<template>
   <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
         <div class="sm:flex-auto">
            <h1 class="text-base font-semibold leading-6 text-gray-900">Bookings</h1>
            <p class="mt-2 text-sm text-gray-700">A list of all the bookings created in our database</p>
         </div>
      </div>
      <div class="mt-8 flow-root">
         <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
               <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <BookingsTable :subAgents="mySubAgents" :bookings="bookings" :isLoading="isLoading" />
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import BookingsTable from '@/components/account/BookingsTable.vue'
import Admin from '@/composables/admin'
import { isAdminLoggedIn, logout, getListingFilterName, getListingFilterDuration, resetListingFilters } from '@/utils/common'
import router from '@/router'

const { allBookings, agentSubAgents } = Admin()
const isLoading = ref<boolean>(true)
let authAdminUser = ref<any>(null)
const bookings = ref<any>(null)
const mySubAgents = ref([])

onMounted(async () => {

   authAdminUser = isAdminLoggedIn()
   await fetchBookings()
})

const fetchBookings = async () => {

   isLoading.value = true
   let response : any

   response = await allBookings()
   isLoading.value = false

   if (response.status == 200) {
      
      const currentDate = new Date()
      const now = new Date()
      now.setHours(0, 0, 0, 0)

      const bookingStatusFilter = getListingFilterName()
      const bookingDurationFilter = getListingFilterDuration()
      let filteredBookings = response.data

      if (bookingStatusFilter && bookingStatusFilter != '') {

         filteredBookings = filteredBookings.filter((booking: any) => {
            
            const [day, month, year] = booking.search && booking.search.pickUp ? booking.search.pickUp.date.split('-').map(Number) : [0, 0, 0]
            const bookingDate = new Date(year, month - 1, day)

            if (booking.status === "confirmed" && bookingStatusFilter == 'confirmed') {
               return true
            } else if (booking.status === "cancelled" && bookingStatusFilter == 'cancelled') {
               return true
            } else if (booking.status === "incomplete" && bookingStatusFilter == 'incomplete') {
               return true
            } else if (bookingStatusFilter == 'awaiting_payment' && booking.status == "awaiting_payment") {
               return true
            } else if (bookingStatusFilter === 'pending' && booking.status !== "confirmed" && booking.status !== "cancelled" && booking.status !== "incomplete" && bookingDate > currentDate) {
               return true
            } else if (bookingStatusFilter === 'expired' && booking.status !== "confirmed" && booking.status !== "cancelled" && booking.status !== "incomplete" && bookingDate <= currentDate) {
               return true
            } else if (bookingStatusFilter == 'all') {
               return true
            } else {
               return false
            }
         })
      }

      if (bookingDurationFilter) {

         filteredBookings = filteredBookings.filter((booking: any) => {
            const bookingDate = new Date(booking.createdAt * 1000)
            bookingDate.setHours(0, 0, 0, 0)

            if (bookingDurationFilter === "today") {
               return bookingDate.toDateString() === now.toDateString()
            } else if (bookingDurationFilter === "yesterday") {
               const yesterday = new Date(now)
               yesterday.setDate(now.getDate() - 1)
               return bookingDate.toDateString() === yesterday.toDateString()
            } else if (bookingDurationFilter === "last_7_days") {
               const sevenDaysAgo = new Date(now)
               sevenDaysAgo.setDate(now.getDate() - 7)

               if (now.getMonth() === 0 && now.getDate() <= 7) { 
                  const decemberStart = new Date(now.getFullYear() - 1, 11, 31)
                  const decemberEnd = new Date(now.getFullYear() - 1, 11, 31)
                  decemberStart.setDate(decemberStart.getDate() - (6 - now.getDate()))

                  const januaryStart = new Date(now.getFullYear(), 0, 1) 
                  const januaryEnd = new Date(now)

                  return (
                     (bookingDate >= decemberStart && bookingDate <= decemberEnd) ||
                     (bookingDate >= januaryStart && bookingDate <= januaryEnd)
                  )
               }

               return bookingDate >= sevenDaysAgo && bookingDate <= now
            } else if (bookingDurationFilter === "this_month") {
               return bookingDate.getFullYear() === now.getFullYear() && bookingDate.getMonth() === now.getMonth()
            } else if (bookingDurationFilter === "last_month") {
               const lastMonth = new Date(now)
               if (now.getMonth() === 0) {
                  lastMonth.setFullYear(now.getFullYear() - 1)
                  lastMonth.setMonth(11)
               } else {
                  lastMonth.setMonth(now.getMonth() - 1)
               }
               lastMonth.setDate(1)

               const firstDayLastMonth = new Date(lastMonth)
               lastMonth.setMonth(lastMonth.getMonth() + 1)
               lastMonth.setDate(0)

               return bookingDate >= firstDayLastMonth && bookingDate <= lastMonth
            } else if (bookingDurationFilter === "all_time") {
               return true
            } else {
               return false
            }
         })
      }

      bookings.value = filteredBookings
      resetListingFilters()
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
}

</script>