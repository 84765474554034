<template>

   <div class="flex flex-col py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0">
      <div class="grid grid-cols-12 md:gap-10"> 
         <div class="col-span-12 md:col-span-3 flex flex-col h-fit order-2 md:order-1 mt-12 md:mt-0">
            <BookingSummary :currentStep="currentStep" />
            <div @click="handleEditSearch" class="gap-4 uppercase mx-auto mt-12 rounded-full w-full md:px-8 py-4 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer ml-auto transition-all ease-in-out duration-500">
               <span class="text-theme-gray-2">
                  <font-awesome-icon size="lg" icon="fa-solid fa-chevron-left" />
               </span>
               <span>Edit Search</span>
            </div>
         </div>
         <div class="col-span-12 md:col-span-9 flex flex-col order-1 md:order-2">
            <div v-if="selectedCar == null" class="flex flex-col">
               <div v-if="selectedCar == null" class="bg-theme-gray-7 px-5 py-4">
                  <div class="text-theme-gray-2 uppercase text-size-2">Filter your Results</div>
               </div>
               <div v-if="selectedCar == null" class="grid grid-cols-2 border-b border-r border-l border-theme-gray-7 md:divide-x divide-y divide-theme-gray-7 shadow-custom">
                  <div class="col-span-6 md:col-span-1 px-5 py-4 flex flex-col">
                     <div class="text-theme-gray-2 uppercase text-small">Passengers</div>
                     <div class="py-2">
                        <Dropdown name="passengers" :data="passengers" @item="handleDropItemSelected" />
                     </div>
                  </div>
                  <div class="col-span-6 md:col-span-1 px-5 py-4 flex flex-col">
                     <div class="text-theme-gray-2 uppercase text-small">Suitcases</div>
                     <div class="py-2">
                        <Dropdown name="suitcases" :data="suitcases" @item="handleDropItemSelected" />
                     </div>
                  </div>
               </div>
               <div v-if="isLoading"></div>
               <div v-else-if="cars && cars.length" class="flex flex-col mt-10 divide-y divide-theme-gray-7">
                  <CarResult v-for="(car, index) in filteredCars" :key="index" isLead="1" :car="car" :canSelect="true" :selectedCar="selectedCar" @selected="handleCarSelected" />
               </div>
            </div>
            <div v-else>
               <div class="bg-theme-gray-7 px-5 py-4">
                  <div class="text-theme-gray-2 uppercase text-size-2">Your Car</div>
               </div>
               <CarResult isLead="1" :car="selectedCar" :canSelect="false" :selectedCar="selectedCar" />
               <div class="flex flex-col">
                  <div class="bg-theme-gray-7 px-5 py-3.5 text-theme-gray-2 text-size-2 uppercase">Guest Contact details</div>
               </div>
               <div class="flex flex-col">
                  <div class="grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7">
                     <div class="col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5">
                        <div :class="!errors.firstName ? 'text-theme-gray-2' : 'text-theme-error'" class="uppercase text-small flex items-center gap-1">
                           <span>First name *</span>
                           <span v-if="errors.firstName" class="text-theme-error">(required)</span>
                        </div>
                        <input v-model="contactDetails.firstName" type="text" class="outline-none w-full h-30 font-bold text-size-6 border-none ">
                     </div>
                     <div class="col-span-2 md:col-span-1">
                        <div class="col-span-1 flex flex-col gap-2 items-start px-4 py-5">
                           <div :class="!errors.lastName ? 'text-theme-gray-2' : 'text-theme-error'" class="uppercase text-small flex items-center gap-1">
                              <span>Last name *</span>
                              <span v-if="errors.lastName" class="text-theme-error">(required)</span>
                           </div>
                           <input v-model="contactDetails.lastName" type="text" class="outline-none w-full h-30 font-bold text-size-6 border-none ">
                        </div>
                     </div>
                  </div>
                  <div class="grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7">
                     <div class="col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5">
                        <div :class="!errors.email ? 'text-theme-gray-2' : 'text-theme-error'" class="uppercase text-small flex items-center gap-1">
                           <span>Email *</span>
                           <span v-if="errors.email" class="text-theme-error">({{ errors.email }})</span>
                        </div>
                        <input v-model="contactDetails.email" type="text" class="outline-none w-full h-30 font-bold text-size-6 border-none ">
                     </div>
                     <div class="col-span-2 md:col-span-1">
                        <div class="col-span-1 flex flex-col gap-2 items-start px-4 py-5 relative">
                           <div :class="!errors.phoneNumber ? 'text-theme-gray-2' : 'text-theme-error'" class="uppercase text-small flex items-center gap-1">
                              <span>Phone number *</span>
                              <span v-if="errors.phoneNumber" class="text-theme-error">({{ errors.phoneNumber }})</span>
                           </div>
                           <CountrySearchablePhoneField @phoneNumber="handlePhoneChange" />
                        </div>
                     </div>
                  </div>
                  <div class="grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7">
                     <div class="col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5">
                        <div class="uppercase text-small text-theme-gray-2 flex items-center gap-1">
                           <span>Flight Number (optional)</span>
                        </div>
                        <input v-model="contactDetails.flightNumber" type="text" class="outline-none w-full h-30 font-bold text-size-6 border-none ">
                     </div>
                     <div class="col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5">
                        <div class="uppercase text-theme-gray-2 text-small">Comments</div>
                        <textarea v-model="contactDetails.comments" class="outline-none w-full h-30 font-bold text-size-6 border-none h-[60px]"></textarea>
                     </div>
                  </div>
               </div>
               <div @click="handleBookingProceed" class="gap-4 hover:bg-white hover:text-theme-active cursor-pointer uppercase mt-12 rounded-full px-8 py-4 border-2 border-theme-active bg-theme-active text-white text-size-2 flex items-center justify-center w-full md:w-fit md:ml-auto transition-all ease-in-out duration-500">
                  <span>Request a Quote</span>
                  <span>
                     <font-awesome-icon size="lg" icon="fa-solid fa-chevron-right" />
                  </span>
               </div>
            </div>
         </div>
      </div>
   </div>
   <Loader :show="isLoading" />

</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { disableScroll, enableScroll, handleApiError, isValidEmail, resetErrors, saveBookingNumber } from '@/utils/common'
import { useRouter } from 'vue-router'
import Car from '@/composables/Car'
import { BookingStep, Car as CarType, UserSearch } from '@/types'
import CarResult from '@/components/CarResult.vue'
import { BOOKING_STEPS, LOADER_MINIMUM_TIME, PASSENGERS, SUITCASES } from '@/utils/constants'
import { retrieveUserSearch, saveUserTempSearch } from '@/services/search-service'
import BookingSummary from '@/components/BookingSummary.vue'
import Loader from '@/widgets/Loader.vue'
import Dropdown from '@/components/Dropdown.vue'
import CountrySearchablePhoneField from '@/components/CountrySearchablePhoneField.vue'
import BookingService from '@/services/booking-service'
import CarService from '@/services/car-service'

const { createBooking, updateBookingAsLead } = BookingService()
const { allCars }  = Car()
const router = useRouter()
const cars = ref<CarType[]>()
const selectedCar = ref<CarType>()
const showContactForm = ref<boolean>(false)
const passengers = PASSENGERS
const isLoading = ref<boolean>(false)
const suitcases = SUITCASES
const { saveSelectedCar }  = CarService()
const filters = ref<any>({
   passengers: null,
   doors: null,
   suitcases: null,
   transmission: null
})
const contactDetails = ref<any>({
   firstName: '',
   phoneNumber: '',
   flightNumber: '',
   lastName: '',
   email: '',
   comments: '',
   needConfirmation: false,
})
const errors = ref<any>({
   firstName: null,
   lastName: null,
   email: null,
   phoneNumber: null,
   responseError: null
})
const timeElapsed = ref<number>(0)
const steps = ref<BookingStep[]>(BOOKING_STEPS)
const currentStep = steps.value.filter((step) => step.uri === 'choose-vehicle')
const userSearch = ref<UserSearch>(retrieveUserSearch())
const handleCarSelected = (car: CarType) => {
   selectedCar.value = car
   selectedCar.value.price = 0

   showContactForm.value = true
   cars.value = [selectedCar.value]
}
const filteredCars = computed(() => {
   return cars.value?.filter((car: any) => {
      return (
         (!filters.value.passengers || car.type.passengers >= filters.value.passengers) &&
         (!filters.value.doors || car.type.doors === filters.value.doors) &&
         (!filters.value.suitcases || car.type.bags >= filters.value.suitcases) &&
         (!filters.value.transmission || car.transmission === filters.value.transmission) &&
         (car.type.id !== 4)
      )
   })
})

const handleDropItemSelected = (item: any, name: string) => {

   if (name == 'passengers') {
      filters.value.passengers = item.value.value === '' ? null : item.value.value
   } else if (name == 'suitcases') {
      filters.value.suitcases = item.value.value === '' ? null : item.value.value
   } else if (name == 'doors') {
      filters.value.doors = item.value.value === '' ? null : item.value.value
   } else if (name == 'transmission') {
      filters.value.transmission = item.value.value === '' ? null : item.value.value
   } 
}

const handleEditSearch = () => {
   saveUserTempSearch(userSearch.value)
   router.push({name: 'Home'})
}

const handlePhoneChange = (value: string) => {
   contactDetails.value.phoneNumber = value
}

const handleBookingProceed = async () => {
   
   resetErrors(errors.value)
   let isValid = validateForm()

   if (!isLoading.value && isValid) {

      if (!selectedCar) {
         console.log('Error in data collection: ')
      }

      saveSelectedCar(selectedCar.value as CarType)
      isLoading.value = true
      const createResponse = await createBooking(selectedCar.value as CarType, userSearch.value as UserSearch)
      if (createResponse.success) {

         saveBookingNumber(createResponse.data.data.number)
         const updateResponse = await updateBookingAsLead(createResponse.data.data.number, contactDetails.value)
         if (updateResponse.success) {
            router.push({ name: 'BookingWizard', params: { step: 'finish' } })
         }
      }
   }
}

const validateForm = () => {
   
   let response = true
   
   if (contactDetails.value.firstName === '') {
      errors.value.firstName = true
      response = false
   }
   
   if (contactDetails.value.lastName === '') {
      errors.value.lastName = true
      response = false
   }

   if (contactDetails.value.phoneNumber === '') {
      errors.value.phoneNumber = 'Required'
      response = false
   }else if (contactDetails.value.phoneNumber.length < 11) {
      errors.value.phoneNumber = 'Invalid'
      response = false
   }

   if (contactDetails.value.email === '') {
      errors.value.email = 'Required'
      response = false
   } else if (!isValidEmail(contactDetails.value.email)) {
      errors.value.email = 'Invalid'
      response = false
   }

   return response
}

onMounted(async () => {

   isLoading.value = true
   disableScroll()
   const startTime = performance.now()
   const response = await allCars()
   timeElapsed.value = performance.now() - startTime
   if (timeElapsed.value < LOADER_MINIMUM_TIME) {

      const diff = LOADER_MINIMUM_TIME - timeElapsed.value
      setTimeout(() => {
         enableScroll()
         isLoading.value = false
      }, diff)
   } else {
      enableScroll()
      isLoading.value = false
   }

   if (response.status == 200) {
      cars.value = response.data.cars
   } else {
      const error = handleApiError(response)
   }
})

</script>