import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-col py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0" }
const _hoisted_2 = { class: "grid grid-cols-12 md:gap-10" }
const _hoisted_3 = { class: "col-span-12 md:col-span-3 flex flex-col h-fit order-2 md:order-1 mt-12 md:mt-0" }
const _hoisted_4 = { class: "text-theme-gray-2" }
const _hoisted_5 = { class: "col-span-12 md:col-span-9 flex flex-col order-1 md:order-2" }
const _hoisted_6 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_7 = {
  key: 0,
  class: "bg-theme-gray-7 px-5 py-4"
}
const _hoisted_8 = {
  key: 1,
  class: "grid grid-cols-2 border-b border-r border-l border-theme-gray-7 md:divide-x divide-y divide-theme-gray-7 shadow-custom"
}
const _hoisted_9 = { class: "col-span-6 md:col-span-1 px-5 py-4 flex flex-col" }
const _hoisted_10 = { class: "py-2" }
const _hoisted_11 = { class: "col-span-6 md:col-span-1 px-5 py-4 flex flex-col" }
const _hoisted_12 = { class: "py-2" }
const _hoisted_13 = { key: 2 }
const _hoisted_14 = {
  key: 3,
  class: "flex flex-col mt-10 divide-y divide-theme-gray-7"
}
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "flex flex-col" }
const _hoisted_17 = { class: "grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7" }
const _hoisted_18 = { class: "col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5" }
const _hoisted_19 = {
  key: 0,
  class: "text-theme-error"
}
const _hoisted_20 = { class: "col-span-2 md:col-span-1" }
const _hoisted_21 = { class: "col-span-1 flex flex-col gap-2 items-start px-4 py-5" }
const _hoisted_22 = {
  key: 0,
  class: "text-theme-error"
}
const _hoisted_23 = { class: "grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7" }
const _hoisted_24 = { class: "col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5" }
const _hoisted_25 = {
  key: 0,
  class: "text-theme-error"
}
const _hoisted_26 = { class: "col-span-2 md:col-span-1" }
const _hoisted_27 = { class: "col-span-1 flex flex-col gap-2 items-start px-4 py-5 relative" }
const _hoisted_28 = {
  key: 0,
  class: "text-theme-error"
}
const _hoisted_29 = { class: "grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7" }
const _hoisted_30 = { class: "col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5" }
const _hoisted_31 = { class: "col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5" }

import { computed, onMounted, ref } from 'vue'
import { disableScroll, enableScroll, handleApiError, isValidEmail, resetErrors, saveBookingNumber } from '@/utils/common'
import { useRouter } from 'vue-router'
import Car from '@/composables/Car'
import { BookingStep, Car as CarType, UserSearch } from '@/types'
import CarResult from '@/components/CarResult.vue'
import { BOOKING_STEPS, LOADER_MINIMUM_TIME, PASSENGERS, SUITCASES } from '@/utils/constants'
import { retrieveUserSearch, saveUserTempSearch } from '@/services/search-service'
import BookingSummary from '@/components/BookingSummary.vue'
import Loader from '@/widgets/Loader.vue'
import Dropdown from '@/components/Dropdown.vue'
import CountrySearchablePhoneField from '@/components/CountrySearchablePhoneField.vue'
import BookingService from '@/services/booking-service'
import CarService from '@/services/car-service'


export default /*@__PURE__*/_defineComponent({
  __name: 'BookingLead',
  setup(__props) {

const { createBooking, updateBookingAsLead } = BookingService()
const { allCars }  = Car()
const router = useRouter()
const cars = ref<CarType[]>()
const selectedCar = ref<CarType>()
const showContactForm = ref<boolean>(false)
const passengers = PASSENGERS
const isLoading = ref<boolean>(false)
const suitcases = SUITCASES
const { saveSelectedCar }  = CarService()
const filters = ref<any>({
   passengers: null,
   doors: null,
   suitcases: null,
   transmission: null
})
const contactDetails = ref<any>({
   firstName: '',
   phoneNumber: '',
   flightNumber: '',
   lastName: '',
   email: '',
   comments: '',
   needConfirmation: false,
})
const errors = ref<any>({
   firstName: null,
   lastName: null,
   email: null,
   phoneNumber: null,
   responseError: null
})
const timeElapsed = ref<number>(0)
const steps = ref<BookingStep[]>(BOOKING_STEPS)
const currentStep = steps.value.filter((step) => step.uri === 'choose-vehicle')
const userSearch = ref<UserSearch>(retrieveUserSearch())
const handleCarSelected = (car: CarType) => {
   selectedCar.value = car
   selectedCar.value.price = 0

   showContactForm.value = true
   cars.value = [selectedCar.value]
}
const filteredCars = computed(() => {
   return cars.value?.filter((car: any) => {
      return (
         (!filters.value.passengers || car.type.passengers >= filters.value.passengers) &&
         (!filters.value.doors || car.type.doors === filters.value.doors) &&
         (!filters.value.suitcases || car.type.bags >= filters.value.suitcases) &&
         (!filters.value.transmission || car.transmission === filters.value.transmission) &&
         (car.type.id !== 4)
      )
   })
})

const handleDropItemSelected = (item: any, name: string) => {

   if (name == 'passengers') {
      filters.value.passengers = item.value.value === '' ? null : item.value.value
   } else if (name == 'suitcases') {
      filters.value.suitcases = item.value.value === '' ? null : item.value.value
   } else if (name == 'doors') {
      filters.value.doors = item.value.value === '' ? null : item.value.value
   } else if (name == 'transmission') {
      filters.value.transmission = item.value.value === '' ? null : item.value.value
   } 
}

const handleEditSearch = () => {
   saveUserTempSearch(userSearch.value)
   router.push({name: 'Home'})
}

const handlePhoneChange = (value: string) => {
   contactDetails.value.phoneNumber = value
}

const handleBookingProceed = async () => {
   
   resetErrors(errors.value)
   let isValid = validateForm()

   if (!isLoading.value && isValid) {

      if (!selectedCar) {
         console.log('Error in data collection: ')
      }

      saveSelectedCar(selectedCar.value as CarType)
      isLoading.value = true
      const createResponse = await createBooking(selectedCar.value as CarType, userSearch.value as UserSearch)
      if (createResponse.success) {

         saveBookingNumber(createResponse.data.data.number)
         const updateResponse = await updateBookingAsLead(createResponse.data.data.number, contactDetails.value)
         if (updateResponse.success) {
            router.push({ name: 'BookingWizard', params: { step: 'finish' } })
         }
      }
   }
}

const validateForm = () => {
   
   let response = true
   
   if (contactDetails.value.firstName === '') {
      errors.value.firstName = true
      response = false
   }
   
   if (contactDetails.value.lastName === '') {
      errors.value.lastName = true
      response = false
   }

   if (contactDetails.value.phoneNumber === '') {
      errors.value.phoneNumber = 'Required'
      response = false
   }else if (contactDetails.value.phoneNumber.length < 11) {
      errors.value.phoneNumber = 'Invalid'
      response = false
   }

   if (contactDetails.value.email === '') {
      errors.value.email = 'Required'
      response = false
   } else if (!isValidEmail(contactDetails.value.email)) {
      errors.value.email = 'Invalid'
      response = false
   }

   return response
}

onMounted(async () => {

   isLoading.value = true
   disableScroll()
   const startTime = performance.now()
   const response = await allCars()
   timeElapsed.value = performance.now() - startTime
   if (timeElapsed.value < LOADER_MINIMUM_TIME) {

      const diff = LOADER_MINIMUM_TIME - timeElapsed.value
      setTimeout(() => {
         enableScroll()
         isLoading.value = false
      }, diff)
   } else {
      enableScroll()
      isLoading.value = false
   }

   if (response.status == 200) {
      cars.value = response.data.cars
   } else {
      const error = handleApiError(response)
   }
})


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(BookingSummary, { currentStep: _unref(currentStep) }, null, 8, ["currentStep"]),
          _createElementVNode("div", {
            onClick: handleEditSearch,
            class: "gap-4 uppercase mx-auto mt-12 rounded-full w-full md:px-8 py-4 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer ml-auto transition-all ease-in-out duration-500"
          }, [
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_font_awesome_icon, {
                size: "lg",
                icon: "fa-solid fa-chevron-left"
              })
            ]),
            _cache[5] || (_cache[5] = _createElementVNode("span", null, "Edit Search", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (selectedCar.value == null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (selectedCar.value == null)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[6] || (_cache[6] = [
                      _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-size-2" }, "Filter your Results", -1)
                    ])))
                  : _createCommentVNode("", true),
                (selectedCar.value == null)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Passengers", -1)),
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(Dropdown, {
                            name: "passengers",
                            data: _unref(passengers),
                            onItem: handleDropItemSelected
                          }, null, 8, ["data"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Suitcases", -1)),
                        _createElementVNode("div", _hoisted_12, [
                          _createVNode(Dropdown, {
                            name: "suitcases",
                            data: _unref(suitcases),
                            onItem: handleDropItemSelected
                          }, null, 8, ["data"])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (isLoading.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13))
                  : (cars.value && cars.value.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredCars.value, (car, index) => {
                          return (_openBlock(), _createBlock(CarResult, {
                            key: index,
                            isLead: "1",
                            car: car,
                            canSelect: true,
                            selectedCar: selectedCar.value,
                            onSelected: handleCarSelected
                          }, null, 8, ["car", "selectedCar"]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _cache[16] || (_cache[16] = _createElementVNode("div", { class: "bg-theme-gray-7 px-5 py-4" }, [
                  _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-size-2" }, "Your Car")
                ], -1)),
                _createVNode(CarResult, {
                  isLead: "1",
                  car: selectedCar.value,
                  canSelect: false,
                  selectedCar: selectedCar.value
                }, null, 8, ["car", "selectedCar"]),
                _cache[17] || (_cache[17] = _createElementVNode("div", { class: "flex flex-col" }, [
                  _createElementVNode("div", { class: "bg-theme-gray-7 px-5 py-3.5 text-theme-gray-2 text-size-2 uppercase" }, "Guest Contact details")
                ], -1)),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("div", {
                        class: _normalizeClass([!errors.value.firstName ? 'text-theme-gray-2' : 'text-theme-error', "uppercase text-small flex items-center gap-1"])
                      }, [
                        _cache[9] || (_cache[9] = _createElementVNode("span", null, "First name *", -1)),
                        (errors.value.firstName)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_19, "(required)"))
                          : _createCommentVNode("", true)
                      ], 2),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((contactDetails.value.firstName) = $event)),
                        type: "text",
                        class: "outline-none w-full h-30 font-bold text-size-6 border-none"
                      }, null, 512), [
                        [_vModelText, contactDetails.value.firstName]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("div", {
                          class: _normalizeClass([!errors.value.lastName ? 'text-theme-gray-2' : 'text-theme-error', "uppercase text-small flex items-center gap-1"])
                        }, [
                          _cache[10] || (_cache[10] = _createElementVNode("span", null, "Last name *", -1)),
                          (errors.value.lastName)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_22, "(required)"))
                            : _createCommentVNode("", true)
                        ], 2),
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((contactDetails.value.lastName) = $event)),
                          type: "text",
                          class: "outline-none w-full h-30 font-bold text-size-6 border-none"
                        }, null, 512), [
                          [_vModelText, contactDetails.value.lastName]
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", {
                        class: _normalizeClass([!errors.value.email ? 'text-theme-gray-2' : 'text-theme-error', "uppercase text-small flex items-center gap-1"])
                      }, [
                        _cache[11] || (_cache[11] = _createElementVNode("span", null, "Email *", -1)),
                        (errors.value.email)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_25, "(" + _toDisplayString(errors.value.email) + ")", 1))
                          : _createCommentVNode("", true)
                      ], 2),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((contactDetails.value.email) = $event)),
                        type: "text",
                        class: "outline-none w-full h-30 font-bold text-size-6 border-none"
                      }, null, 512), [
                        [_vModelText, contactDetails.value.email]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("div", {
                          class: _normalizeClass([!errors.value.phoneNumber ? 'text-theme-gray-2' : 'text-theme-error', "uppercase text-small flex items-center gap-1"])
                        }, [
                          _cache[12] || (_cache[12] = _createElementVNode("span", null, "Phone number *", -1)),
                          (errors.value.phoneNumber)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_28, "(" + _toDisplayString(errors.value.phoneNumber) + ")", 1))
                            : _createCommentVNode("", true)
                        ], 2),
                        _createVNode(CountrySearchablePhoneField, { onPhoneNumber: handlePhoneChange })
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, [
                      _cache[13] || (_cache[13] = _createElementVNode("div", { class: "uppercase text-small text-theme-gray-2 flex items-center gap-1" }, [
                        _createElementVNode("span", null, "Flight Number (optional)")
                      ], -1)),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((contactDetails.value.flightNumber) = $event)),
                        type: "text",
                        class: "outline-none w-full h-30 font-bold text-size-6 border-none"
                      }, null, 512), [
                        [_vModelText, contactDetails.value.flightNumber]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_31, [
                      _cache[14] || (_cache[14] = _createElementVNode("div", { class: "uppercase text-theme-gray-2 text-small" }, "Comments", -1)),
                      _withDirectives(_createElementVNode("textarea", {
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((contactDetails.value.comments) = $event)),
                        class: "outline-none w-full h-30 font-bold text-size-6 border-none h-[60px]"
                      }, null, 512), [
                        [_vModelText, contactDetails.value.comments]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", {
                  onClick: handleBookingProceed,
                  class: "gap-4 hover:bg-white hover:text-theme-active cursor-pointer uppercase mt-12 rounded-full px-8 py-4 border-2 border-theme-active bg-theme-active text-white text-size-2 flex items-center justify-center w-full md:w-fit md:ml-auto transition-all ease-in-out duration-500"
                }, [
                  _cache[15] || (_cache[15] = _createElementVNode("span", null, "Request a Quote", -1)),
                  _createElementVNode("span", null, [
                    _createVNode(_component_font_awesome_icon, {
                      size: "lg",
                      icon: "fa-solid fa-chevron-right"
                    })
                  ])
                ])
              ]))
        ])
      ])
    ]),
    _createVNode(Loader, { show: isLoading.value }, null, 8, ["show"])
  ], 64))
}
}

})