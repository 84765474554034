<template>
   <div class="sticky top-0 z-40 flex md:h-16 shrink-0 items-center md:gap-x-4 border-b border-gray-200 bg-white md:px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <Disclosure as="nav" class="bg-white w-full" v-slot="{ open }">
         <div class="px-4 sm:px-6 lg:px-8">
            <div class="flex h-16 justify-between">
            <div class="flex">
               <div class="-ml-2 mr-2 flex items-center md:hidden">
                  <!-- Mobile menu button -->
                  <DisclosureButton class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-theme-active">
                     <span class="absolute -inset-0.5" />
                     <span class="sr-only">Open main menu</span>
                     <font-awesome-icon v-if="!open" size="lg" icon="fa-solid fa-bars" />
                     <font-awesome-icon v-else icon="fa-solid fa-xmark" />
                  </DisclosureButton>
               </div>
               <div @click="router.push({name: 'Home'})" class="flex flex-shrink-0 items-center cursor-pointer">
                  <img class="h-8 w-auto" src="/assets/logo.png" alt="Vehitra World" />
               </div>
               <div class="hidden md:ml-6 md:flex md:space-x-8">
                  <div @click="handleNavClick(item)" v-for="item in navigation" :key="item.name" class="cursor-pointer inline-flex items-center px-1 pt-1 text-sm font-bold" :class="route.path != item.route ? 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700' : 'text-gray-900 border-b-2 border-theme-active'">{{ item.name }}</div>
               </div>
            </div>
            <div class="flex items-center">
               <div class="flex-shrink-0">
                  <button @click="router.push({name: 'Home'})" type="button" class="relative inline-flex items-center gap-x-1.5 rounded-md bg-theme-active px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-active">
                     <font-awesome-icon icon="fa-solid fa-plus" /> Create Booking
                  </button>
               </div>
               <div class="flex-shrink-0 ml-4">
                  <button @click="navigateSettings" type="button" class="relative inline-flex items-center gap-x-1.5 rounded-md bg-theme-button3-bg px-3 py-2 text-sm font-semibold text-theme-gray-3 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-active">
                     <font-awesome-icon icon="fa-solid fa-gear" /> Settings
                  </button>
               </div>
               <div class="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                  <button type="button" class="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-theme-active focus:ring-offset-2">
                     <span class="absolute -inset-1.5" />
                     <span class="sr-only">View notifications</span>
                     <font-awesome-icon size="lg" icon="fa-regular fa-bell" class="" />
                  </button>

                  <!-- Profile dropdown -->
                  <Menu as="div" class="relative ml-3">
                  <div>
                     <MenuButton class="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-theme-active focus:ring-offset-2">
                        <span class="absolute -inset-1.5" />
                        <span class="sr-only">Open user menu</span>
                        <img v-if="authUser.photo" class="h-8 w-8 rounded-full bg-gray-50" :src="authUser.photo" alt="" />
                        <img v-else class="h-8 w-8 rounded-full bg-gray-50" src="/assets/avatars/default.jpg" alt="" />
                     </MenuButton>
                  </div>
                  <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                     <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <MenuItem v-slot="{ active }">
                           <div @click="handleLogout" :class="[active ? 'bg-gray-100' : '', 'cursor-pointer block px-4 py-2 text-sm text-gray-700']">Sign out</div>
                        </MenuItem>
                     </MenuItems>
                  </transition>
                  </Menu>
               </div>
            </div>
            </div>
         </div>

         <DisclosurePanel class="md:hidden">
            <div class="space-y-1 pb-3 pt-2">
               <DisclosureButton @click="handleNavClick(item)" v-for="item in navigation" :key="item.name" as="div" class="block border-l-4 py-2 pl-3 pr-4 text-base font-medium sm:pl-5 sm:pr-6" :class="route.path != item.route ? 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700' : 'bg-theme-active border-theme-active text-white'">{{ item.name }}</DisclosureButton>
            </div>
            <div class="border-t border-gray-200 pb-3 pt-4">
            <div class="flex items-center px-4 sm:px-6">
               <div class="flex-shrink-0">
                  <img v-if="authUser.photo" class="h-8 w-8 rounded-full bg-gray-50" :src="authUser.photo" alt="" />
                  <img v-else class="h-8 w-8 rounded-full bg-gray-50" src="/assets/avatars/default.jpg" alt="" />
               </div>
               <div class="ml-3">
                  <div class="text-base font-medium text-gray-800">
                     {{ authUser.name ? authUser.name : (authUser.firstName + ' ' + authUser.lastName) }}
                  </div>
                  <div class="text-sm font-medium text-gray-500">
                     {{ authUser.email ? authUser.email : '' }}
                  </div>
               </div>
               <button type="button" class="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="absolute -inset-1.5" />
                  <span class="sr-only">View notifications</span>
                  <font-awesome-icon size="lg" icon="fa-regular fa-bell" class="" />
               </button>
            </div>
            <div class="mt-3 space-y-1">
               <DisclosureButton v-if="authUser.type != 'admin'" @click="navigateSettings" as="div" class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6">Settings</DisclosureButton>
               <DisclosureButton @click="handleLogout" as="div" class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6">Sign out</DisclosureButton>
            </div>
            </div>
         </DisclosurePanel>
      </Disclosure>
   </div>
</template>

<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { useRoute, useRouter } from 'vue-router'
import { isAdminLoggedIn, isLoggedIn, logout } from '@/utils/common'
import { ref, onMounted } from 'vue'

const router = useRouter()
const emit = defineEmits(['openSidebar'])
const auth = isLoggedIn()
const route = useRoute()
const adminAuth = isAdminLoggedIn()
const authUser = auth ? auth.user : adminAuth.admin
let navigation : any = []
const userNavigation = [
   { name: 'Log out' },
]

if (auth) {

   if (auth.user.type == 'agent') {

      if (auth.user.sub_type == null) {
         navigation = [
            { name: 'Dashboard', route: router.resolve({ name: 'AccountDashboard' }).href, current: route.name == 'AccountDashboard' },
            { name: 'Bookings', route: router.resolve({ name: 'UserBookings', params: {userType: 'agent'} }).href, current: route.name == 'UserBookings' },
            { name: 'Sub Agents', route: router.resolve({ name: 'SubAgents' }).href, current: route.name == 'SubAgents' },
            { name: 'Reports', route: router.resolve({ name: 'AgentReports' }).href, current: route.name == 'AgentReports' },
         ]
      } else {
         navigation = [
         { name: 'Dashboard', route: router.resolve({ name: 'AccountDashboard' }).href, current: route.name == 'AccountDashboard' },
            { name: 'Bookings', route: router.resolve({ name: 'UserBookings', params: {userType: 'agent'} }).href, current: route.name == 'UserBookings' },
         ]
      }
   } else if (auth.user.type == 'corporate') {
      if (auth.user.sub_type == null) {
         navigation = [
            { name: 'Dashboard', route: router.resolve({ name: 'AccountDashboard' }).href, current: route.name == 'AccountDashboard' },
            { name: 'Bookings', route: router.resolve({ name: 'UserBookings', params: {userType: 'corporate'} }).href, current: route.name == 'UserBookings' },
            { name: 'Sub Users', route: router.resolve({ name: 'SubUsers' }).href, current: route.name == 'SubUsers' }
         ]
      } else {
         navigation = [
            { name: 'Dashboard', route: router.resolve({ name: 'AccountDashboard' }).href, current: route.name == 'AccountDashboard' },
            { name: 'Bookings', route: router.resolve({ name: 'UserBookings', params: {userType: 'corporate'} }).href, current: route.name == 'UserBookings' },
         ]
      }
   }
} else if (adminAuth) {

   navigation = [
      { name: 'Dashboard', route: router.resolve({ name: 'AdminDashboard' }).href, current: route.name == 'AdminDashboard' },
      { name: 'Bookings', route: router.resolve({ name: 'AdminBookings' }).href, current: route.name == 'AdminBookings' },
      { name: 'Cars', route: router.resolve({ name: 'AdminCars' }).href, current: route.name == 'AdminCars' },
      { name: 'Pages', route: router.resolve({ name: 'AdminCustomPage' }).href, current: route.name == 'AdminCustomPage' },
      { name: 'Agents', route: router.resolve({ name: 'AdminAgents' }).href, current: route.name == 'AdminAgents' },
      { name: 'Reports', route: router.resolve({ name: 'AdminReports' }).href, current: route.name == 'AdminReports' },
   ]
}

const handleLogout = () => {
   logout(router)
}

const handleNavClick = (item: any) => {

   router.push(item.route)
}

const navigateSettings = () => {

   if (authUser.type == 'admin') {
      router.push({name: 'AdminSettings'})
   } else {
      router.push({name: 'UserSettings', params: {userType: authUser.type}})
   }
}

</script>