<template>

   <TopBanner layout="normal" currentPage="Our services" heading="Our services" bannerClass="banner-booking" />

   <section class="bg-theme-gray-1 pt-24 pb-32 px-11 md:px-0">
      <div class="flex flex-col mx-auto lg:w-theme-dw">
         <h2 class="font-bold text-theme-active text-size-4 md:text-size-6 text-center mb-3 uppercase">What we offer</h2>
         <div class="font-light text-head-md-2 md:text-head-xl text-theme-gray-5 text-center">See What We Can Do For You</div>
         <div class="grid grid-cols-2 gap-10 mt-20">
            <a href="/" class="relative group col-span-2 md:col-span-1">
               <img class="w-full" src="/assets/8.jpeg" alt="">
               <div class="bg-[#858C9266] w-full h-full absolute top-0 left-0"></div>
               <div class="absolute bottom-4 md:bottom-10 left-4 md:left-8">
                  <div class="h-[1px] w-8 bg-white mb-4 group-hover:w-24 transition-all ease-in-out duration-500"></div>
                  <span class="font-bold text-size-4 md:text-size-6 uppercase text-white">Airport transfer</span>
               </div>
            </a>
            <a href="/" class="relative group col-span-2 md:col-span-1">
               <img class="w-full" src="/assets/7.jpeg" alt="">
               <div class="bg-[#858C9266] w-full h-full absolute top-0 left-0"></div>
               <div class="absolute bottom-4 md:bottom-10 left-4 md:left-8">
                  <div class="h-[1px] w-8 bg-white mb-4 group-hover:w-24 transition-all ease-in-out duration-500"></div>
                  <span class="font-bold text-size-4 md:text-size-6 uppercase text-white">Chauffeur Services</span>
               </div>
            </a>
            
            <div class="grid grid-cols-2 gap-10 col-span-2 md:col-span-1">
               <a href="https://carsirent.com" target="_blank" class="relative group">
                  <img class="w-full" src="/assets/10.jpeg" alt="">
                  <div class="bg-[#858C9266] w-full h-full absolute top-0 left-0"></div>
                  <div class="absolute bottom-4 md:bottom-10 left-4 md:left-8">
                     <div class="h-[1px] w-8 bg-white mb-4 group-hover:w-24 transition-all ease-in-out duration-500"></div>
                     <span class="font-bold text-size-4 md:text-size-6 uppercase text-white">CAR RENTAL</span>
                  </div>
               </a>
               <div class="relative group pointer" @click="router.push({name: 'MeetGreet'})">
                  <img class="w-full" src="/assets/4.jpg" alt="">
                  <div class="bg-[#858C9266] w-full h-full absolute top-0 left-0"></div>
                  <div class="absolute bottom-4 md:bottom-10 left-4 md:left-8">
                     <div class="h-[1px] w-8 bg-white mb-4 group-hover:w-24 transition-all ease-in-out duration-500"></div>
                     <span class="font-bold text-size-4 md:text-size-6 uppercase text-white">MEET & GREET</span>
                  </div>
               </div>
            </div>
            <div class="grid grid-cols-2 gap-10 col-span-2 md:col-span-1">
               
               <div class="relative group pointer" @click="router.push({name: 'BusesCoaches'})">
                  <img class="w-full" src="/assets/busescoaches.jpeg" alt="">
                  <div class="bg-[#858C9266] w-full h-full absolute top-0 left-0"></div>
                  <div class="absolute bottom-4 md:bottom-10 left-4 md:left-8">
                     <div class="h-[1px] w-8 bg-white mb-4 group-hover:w-24 transition-all ease-in-out duration-500"></div>
                     <span class="font-bold text-size-4 md:text-size-6 uppercase text-white">BUSES & COACHES</span>
                  </div>
               </div>
               <div class="relative group pointer" @click="router.push({name: 'Planmytrip'})">
                  <img class="w-full" src="/assets/5.jpg" alt="">
                  <div class="bg-[#858C9266] w-full h-full absolute top-0 left-0"></div>
                  <div class="absolute bottom-4 md:bottom-10 left-4 md:left-8">
                     <div class="h-[1px] w-8 bg-white mb-4 group-hover:w-24 transition-all ease-in-out duration-500"></div>
                     <span class="font-bold text-size-4 md:text-size-6 uppercase text-white">LEISURE & BUSSINESS SPECIAL EVENT</span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   
   <section class="px-11 my-24 gap-12 lg:w-theme-dw md:mx-auto md:px-0 flex flex-col md:flex-row md:items-start">
      <section class="flex-1 flex flex-col gap-4">
         <h2 class="text-theme-gray-5 text-size-8 md:text-head-md">Vehitra World: A Journey Beyond Boundaries</h2>
         
         <p class="text-theme-gray-4 font-light text-size-6">
            In the heart of Riyadh, back in 2001, Vehitra World embarked on a transformative journey in the kingdom of Saudi Arabia. With a visionary commitment to deliver unparalleled transportation solutions, our story unfolded alongside the evolution of technology.
</p>
<p class="text-theme-gray-4 font-light text-size-6">

The inception of our first online booking platform, Carsirent.com, marked the intersection of innovation and convenience. Later, the addition of airporttransfers360.com expanded our reach, connecting us with travelers across GCC countries.

</p><p class="text-theme-gray-4 font-light text-size-6"> As technology progressed, so did we. Through the unwavering trust and support of our local and international partners, Vehitra World's headquarters found a home in Bahrain in 2005, becoming the hub of our continuous success.

</p><p class="text-theme-gray-4 font-light text-size-6"> Today, we proudly unveil a new chapter in our journey, introducing 'Vehitra World.' It's not just a name; it's a promise to cover more services and destinations, reflecting our commitment to evolution.

</p><p class="text-theme-gray-4 font-light text-size-6">This new platform is more than a website; it's a testament to our dedication. Crafted with local minds, it resonates with the pulse of our clients, meeting their exigencies and surpassing expectations.

</p><p class="text-theme-gray-4 font-light text-size-6">Vehitra World is not just about transportation; it's a gateway to experiences, a bridge connecting you to the world. Join us as we redefine the standards of global travel.

</p><p class="text-theme-gray-4 font-light text-size-6">

Vehitra World — Where the journey transcends boundaries, and the world becomes your destination.



         </p>
         <p class="text-theme-gray-4 font-light text-size-6">
            To keep you satisfied, our Customer Support Team is available 24/7 to walk you through every step if you face any problem.
         </p>
      </section>
      <section class="flex-1">
         <div class="flex flex-col gap-10">
            <div class="flex flex-row items-start gap-12">
               <div>
                  <font-awesome-icon size="2xl" icon="fa-solid fa-quote-right" class="text-theme-active" />
               </div>
               <div class="flex flex-col gap-10">
                  <div class="text-theme-gray-4 font-light text-size-7 md:text-head-md-2">
                     As the founder of this company back in 2001, I had a dream of providing the most exceptional and luxurious transportation service to all 
                     our clients. It has been a long and challenging journey, but I am proud to say that we have become one of the most trusted and sought-after 
                     transportation companies in the industry.
                  </div>
               </div>
            </div>
            <div class="text-theme-gray-2 text-size-2 uppercase text-center md:text-right">GHAZI HAMADE - CHAIRMAN & CEO</div>
         </div>
      </section>
   </section>

</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import TopBanner from '@/components/TopBanner.vue'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()

</script>