import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "my-0 md:w-[50%] mx-auto"
}
const _hoisted_2 = { class: "grid grid-cols-1 gap-4 divide-y divide-gray-300 w-full bg-gray-100 p-4 my-12" }
const _hoisted_3 = {
  key: 0,
  class: "text-size-6"
}
const _hoisted_4 = { class: "grid grid-cols-2 gap-4 pt-4" }
const _hoisted_5 = { class: "flex flex-col gap-4 col-span-2 md:col-span-1" }
const _hoisted_6 = { class: "grid grid-cols-2" }
const _hoisted_7 = { class: "col-span-1 font-bold" }
const _hoisted_8 = { class: "col-span-1 flex flex-col gap-4 pt-4" }
const _hoisted_9 = { class: "grid grid-cols-2 gap-4" }
const _hoisted_10 = { class: "flex flex-col gap-4 col-span-2 md:col-span-1" }
const _hoisted_11 = { class: "grid grid-cols-2" }
const _hoisted_12 = { class: "col-span-1 font-bold" }
const _hoisted_13 = { class: "grid grid-cols-2" }
const _hoisted_14 = { class: "col-span-1 font-bold" }
const _hoisted_15 = { class: "flex flex-col gap-4 col-span-2 md:col-span-1" }
const _hoisted_16 = { class: "grid grid-cols-2" }
const _hoisted_17 = { class: "col-span-1 font-bold" }
const _hoisted_18 = { class: "grid grid-cols-2" }
const _hoisted_19 = { class: "col-span-1 font-bold" }
const _hoisted_20 = {
  key: 1,
  class: "col-span-1 flex flex-col gap-4 pt-4"
}
const _hoisted_21 = { class: "grid grid-cols-2 gap-4" }
const _hoisted_22 = { class: "flex flex-col gap-4 col-span-2 md:col-span-1" }
const _hoisted_23 = { class: "grid grid-cols-2" }
const _hoisted_24 = { class: "col-span-1 font-bold" }
const _hoisted_25 = { class: "grid grid-cols-2" }
const _hoisted_26 = { class: "col-span-1 font-bold" }
const _hoisted_27 = { class: "grid grid-cols-2" }
const _hoisted_28 = { class: "col-span-1 font-bold" }
const _hoisted_29 = { class: "grid grid-cols-2" }
const _hoisted_30 = { class: "col-span-1 font-bold" }
const _hoisted_31 = { class: "flex flex-col gap-4 col-span-2 md:col-span-1" }
const _hoisted_32 = { class: "grid grid-cols-2" }
const _hoisted_33 = { class: "col-span-1 font-bold" }
const _hoisted_34 = { class: "col-span-1 flex flex-col gap-4 pt-4" }
const _hoisted_35 = { class: "grid grid-cols-2 gap-4" }
const _hoisted_36 = { class: "flex flex-col gap-4 col-span-2 md:col-span-1" }
const _hoisted_37 = { class: "grid grid-cols-2" }
const _hoisted_38 = { class: "col-span-1 font-bold" }
const _hoisted_39 = { class: "grid grid-cols-2" }
const _hoisted_40 = { class: "col-span-1 font-bold" }
const _hoisted_41 = {
  key: 0,
  class: "flex flex-col gap-4 col-span-2 md:col-span-1"
}
const _hoisted_42 = { class: "grid grid-cols-2" }
const _hoisted_43 = { class: "col-span-1 font-bold" }
const _hoisted_44 = {
  key: 1,
  class: "flex flex-col gap-4 col-span-2 md:col-span-1"
}
const _hoisted_45 = { class: "grid grid-cols-2" }
const _hoisted_46 = { class: "col-span-1 font-bold" }
const _hoisted_47 = { class: "col-span-1 flex flex-col gap-4 pt-4" }
const _hoisted_48 = { class: "grid grid-cols-2 gap-4" }
const _hoisted_49 = { class: "flex flex-col gap-4 col-span-2 md:col-span-1" }
const _hoisted_50 = { class: "grid grid-cols-2" }
const _hoisted_51 = { class: "col-span-1 font-bold" }

import { onMounted, ref } from 'vue'
import Voucher from '@/components/booking/Voucher.vue'
import { Booking } from '@/types'
import BookingService from '@/services/booking-service'
import { getSessionPrefix, isMakePaymentVisible, saveBookingNumber } from '@/utils/common'
import router from '@/router'
// @ts-ignore
import html2pdf from 'html2pdf.js'
import Modal from '@/widgets/Modal.vue'
import { saveUserSearch } from '@/services/search-service'
import CarService from '@/services/car-service'


export default /*@__PURE__*/_defineComponent({
  __name: 'UserBookingDetails',
  props: ['number'],
  setup(__props) {

const props = __props
const data = ref<any>(null)
const isLoading = ref<boolean>(true)
const { readBooking } = BookingService()
const pdfContent = ref(null)
const openConfirmModal = ref<boolean>(false)
const showMakePaymentButton = ref<boolean>(false)
const { saveSelectedCar } = CarService()

onMounted(async () => {

   const response = await readBooking(props.number)
   isLoading.value = false
   
   if (response.success) {
      data.value = response.data.data
   } else {
      console.log(response.error)
   }

   showMakePaymentButton.value = response.data.data.search ? isMakePaymentVisible(response.data.data.search.pickUp.date) : false
})

const downloadPDF = () => {

   const content = pdfContent.value
   html2pdf(content).from(content).save()
}

const handleConfirmClose = () => openConfirmModal.value = false


return (_ctx: any,_cache: any) => {
  return (!isLoading.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "col-span-1 flex flex-row items-center" }, [
            _createElementVNode("img", {
              class: "w-[235px]",
              src: "/assets/logo.png",
              alt: ""
            }),
            _createElementVNode("div", { class: "ml-auto text-size-6" }, "Booking Voucher")
          ], -1)),
          (data.value.refNumber)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Reference Number:", -1)),
                      _createElementVNode("div", _hoisted_7, _toDisplayString(data.value.refNumber), 1)
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Name:", -1)),
                  _createElementVNode("div", _hoisted_12, _toDisplayString(data.value?.contact.name), 1)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Email:", -1)),
                  _createElementVNode("div", _hoisted_14, _toDisplayString(data.value?.contact.email), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Phone number:", -1)),
                  _createElementVNode("div", _hoisted_17, _toDisplayString(data.value?.contact.phone), 1)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _cache[4] || (_cache[4] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Comments:", -1)),
                  _createElementVNode("div", _hoisted_19, _toDisplayString(data.value?.comments), 1)
                ])
              ])
            ])
          ]),
          (data.value.car)
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, [
                      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Car:", -1)),
                      _createElementVNode("div", _hoisted_24, _toDisplayString(data.value?.car.name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Type:", -1)),
                      _createElementVNode("div", _hoisted_26, _toDisplayString(data.value?.car.type.name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_27, [
                      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Bags:", -1)),
                      _createElementVNode("div", _hoisted_28, _toDisplayString(data.value?.car.type.bags), 1)
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Passengers:", -1)),
                      _createElementVNode("div", _hoisted_30, _toDisplayString(data.value?.car.type.passengers), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("div", _hoisted_32, [
                      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Doors:", -1)),
                      _createElementVNode("div", _hoisted_33, _toDisplayString(data.value?.car.type.doors), 1)
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("div", _hoisted_35, [
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("div", _hoisted_37, [
                  _cache[10] || (_cache[10] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Pick Up:", -1)),
                  _createElementVNode("div", _hoisted_38, _toDisplayString(data.value?.search ? data.value.search.pickUp.location.name : ''), 1)
                ]),
                _createElementVNode("div", _hoisted_39, [
                  _cache[11] || (_cache[11] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Date and time:", -1)),
                  _createElementVNode("div", _hoisted_40, _toDisplayString(data.value?.search ? data.value?.search.pickUp.date : '') + " - " + _toDisplayString(data.value?.search ? data.value.search.pickUp.time : ''), 1)
                ])
              ]),
              (data.value.search && data.value?.search.type == 'hourly')
                ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                    _createElementVNode("div", _hoisted_42, [
                      _cache[12] || (_cache[12] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Booking hours:", -1)),
                      _createElementVNode("div", _hoisted_43, _toDisplayString(data.value.search ? data.value.search.hours : ''), 1)
                    ])
                  ]))
                : (data.value.search && data.value?.search.type == 'distance')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                      _createElementVNode("div", _hoisted_45, [
                        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Drop Off:", -1)),
                        _createElementVNode("div", _hoisted_46, _toDisplayString(data.value.search ? data.value.search.dropOff.location.name : ''), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_47, [
            _createElementVNode("div", _hoisted_48, [
              _createElementVNode("div", _hoisted_49, [
                _createElementVNode("div", _hoisted_50, [
                  _cache[14] || (_cache[14] = _createElementVNode("div", { class: "col-span-1 text-theme-gray-4" }, "Total amount:", -1)),
                  _createElementVNode("div", _hoisted_51, _toDisplayString(data.value?.totalAmount) + "USD", 1)
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})