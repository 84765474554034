import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CKEditor from '@ckeditor/ckeditor5-vue'

import "primevue/resources/themes/lara-light-green/theme.css"
import "primeicons/primeicons.css"
import PrimeVue from "primevue/config"
import Tailwind from "primevue/passthrough/tailwind";
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import MultiSelect from 'primevue/multiselect'
import Dropdown from 'primevue/dropdown'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import Paginator from 'primevue/paginator'

import { 
   faMobileScreen, 
   faQuoteRight, 
   faChevronDown,
   faChevronUp,
   faChevronRight,
   faChevronLeft,
   faCheckDouble,
   faBars,
   faCheck,
   faSpinner,
   faCartShopping,
   faEye,
   faCheckCircle,
   faCircle,
   faTimesCircle,
   faXmark,
   faGear,
   faHouse,
   faList,
   faSearch,
   faCircleExclamation,
   faPlane,
   faLocationDot,
   faCarSide,
   faPlus,
   faBook,
   faHome,
   faCar,
   faDollar
} from '@fortawesome/free-solid-svg-icons'
import { faMap, faBell } from '@fortawesome/free-regular-svg-icons'

library.add(faMobileScreen, faCheckDouble, faHome, faCar, faDollar, faCircle, faBook, faQuoteRight, faMap, faChevronDown, faPlus, faBars, faPlane, faChevronUp, faLocationDot, faCheck, faSpinner, faCarSide, faCircleExclamation, faChevronRight, faCartShopping, faSearch, faBell, faHouse, faList, faChevronLeft, faGear, faEye, faCheckCircle, faTimesCircle, faXmark)

const app = createApp(App)
app.use(router)
app.use(CKEditor)
app.use(PrimeVue, { ripple: true, unstyled: true, pt: Tailwind  })
app.component('Button', Button)
app.component('Column', Column)
app.component('DataTable', DataTable)
app.component('Dropdown', Dropdown)
app.component('InputText', InputText)
app.component('MultiSelect', MultiSelect)
app.component('Paginator', Paginator)
app.component('font-awesome-icon', FontAwesomeIcon).mount('#app')
