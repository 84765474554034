<template>

   <div class="flex flex-col bg-theme-gray-1 px-4 py-8 ">
      <div class="text-theme-gray-5 text-size-6 font-bold mb-3">Summary</div>
      <div v-if="userSearch" class="flex flex-col divide-y divide-theme-gray-9 gap-5">
         <div v-if="selectedCar && currentStep?.uri == 'contact-details'" class="flex flex-col gap-2 pt-4">
            <div class="text-theme-gray-2 uppercase text-small">Vehicle selected</div>
            <!--<div class="flex items-center gap-2">
               <div class="text-theme-gray-5 text-size-3 capitalize">Name: </div>
               <div class="text-theme-gray-5 text-size-3">
                  {{selectedCar.name}}
               </div>
            </div>-->
            <div v-if="selectedCar.type" class="flex items-center gap-2">
               <div class="text-theme-gray-5 text-size-3 capitalize">Type: </div>
               <div class="text-theme-gray-5 text-size-3">
                  {{selectedCar.type.name}}
               </div>
            </div>
            <div v-if="userSearch.type == 'hourly' && selectedCar.dailyrate" class="flex items-center gap-2">
               <div class="text-theme-gray-5 text-size-3 capitalize">Rate Per Day: </div>
               <div class="text-theme-gray-5 text-size-3">
                  {{selectedCar.dailyrate?.toFixed(2)}} USD
               </div>
            </div>
            <div v-if="userSearch.type == 'hourly' && userSearch.hours" class="flex items-center gap-2">
               <div class="text-theme-gray-5 text-size-3 capitalize">No of days: </div>
               <div class="text-theme-gray-5 text-size-3">
                  {{userSearch.hours / getHoursPerDay()}}
               </div>
            </div>
            <div v-if="selectedCar.price && selectedCar.price > 0" class="flex items-center gap-2">
               <div class="text-theme-gray-5 text-size-3 capitalize">Total Price: </div>
               <div class="text-theme-gray-5 text-size-3">
                  {{selectedCar.price.toFixed(2)}} USD
               </div>
            </div>
            <div v-if="selectedCar.price && selectedCar.price > 0 && userSearch.type == 'hourly'" class="font-bold text-sm text-theme-active">
               <p>Note: Rate is for {{ getHoursPerDay() }} continuous hours within the city limits of {{ selectedCar.chaufferLocation?.locality.name }} </p>
            </div>
         </div>
         <div class="flex flex-col gap-2 pt-4">
            <div class="text-theme-gray-2 uppercase text-small">Service type</div>
            <div class="text-theme-gray-5 text-size-3 capitalize">{{ userSearch.type == 'hourly' ? 'Chauffeur' : 'Transfer' }}</div>
         </div>
         <div class="flex flex-col gap-2 pt-4">
            <div class="text-theme-gray-2 uppercase text-small">Pickup date & time</div>
            <div class="text-theme-gray-5 text-size-3">{{ userSearch.pickUp.date }}, {{ userSearch.pickUp.time }}</div>
         </div>
         <div class="flex flex-col gap-2 pt-4">
            <div class="text-theme-gray-2 uppercase text-small">Pickup location</div>
            <div @click="handlePickUpClick" class="text-theme-gray-5 text-size-3 hover:underline cursor-pointer">{{ userSearch.pickUp.location.name }}</div>
            <GoogleMap :origin="userSearch.pickUp.location.id" :destination="userSearch.dropOff.location.id" :driveType="userSearch.type" @distance="handleMapData" @time="handleMapData" />
         </div>
         <div class="hidden flex flex-col gap-2 pt-4">
            <div class="text-theme-gray-2 uppercase text-small">Transfer type</div>
            <div class="text-theme-gray-5 text-size-3 capitalize">{{ userSearch.mode }}</div>
         </div>
         <div v-if="userSearch.type == 'distance'" class="flex flex-col gap-2 pt-4">
            <div class="text-theme-gray-2 uppercase text-small">Drop-off location</div>
            <div class="text-theme-gray-5 text-size-3">{{ userSearch.dropOff.location.name }}</div>
         </div>
         <div v-if="userSearch.type == 'distance'" class="flex flex-row items-start pt-4 gap-2">
            <div class="flex-1 flex flex-col gap-2">
               <div class="text-theme-gray-2 uppercase text-small">Total distance</div>
               <div class="text-theme-gray-5 text-size-3">{{ distance }}</div>
            </div>
            <div class="flex-1 flex flex-col gap-2">
               <div class="text-theme-gray-2 uppercase text-small">Total time</div>
               <div class="text-theme-gray-5 text-size-3">{{ time }}</div>
            </div>
         </div>
      </div>
   </div>

</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { retrieveUserSearch } from '@/services/search-service' 
import { UserSearch, Car as CarData, LocationSchedule } from '@/types'
import { getSessionPrefix, getHoursPerDay } from '@/utils/common'
import GoogleMap from '@/components/GoogleMap.vue'

const props = defineProps(['currentStep'])
const userSearch = ref<UserSearch | null>(null)
const selectedCar = ref<CarData | null>(null)
const distance = ref<string>('0 km')
const time = ref<string>('0h 0m')

const handleMapData = (value: string, type: string) => {
   if (type == 'distance') {
      distance.value = value
   } else if(type == 'time') {
      time.value = value
   }
}

const handlePickUpClick = () => {
 
   const locationName = userSearch.value?.pickUp.location.name as string
   const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(locationName)}`
   window.open(googleMapsUrl, '_blank')
}  

onMounted(() => {

   userSearch.value = retrieveUserSearch()
   selectedCar.value = retrieveSelectedVehicle()
})

const retrieveSelectedVehicle = () : CarData => {

   const prefix = getSessionPrefix()
   const carString = localStorage.getItem(`${prefix}_selected_car`)

   return carString ? JSON.parse(carString) : null
}

</script>