import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex gap-2 w-full justify-end" }
const _hoisted_2 = { class: "flex items-center gap-2 p-input-icon-left" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "flex flex-col gap-1" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "flex items-center gap-2" }
const _hoisted_8 = ["alt", "src"]
const _hoisted_9 = { class: "flex items-center gap-2" }
const _hoisted_10 = ["alt", "src"]
const _hoisted_11 = { class: "flex items-center gap-2" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = {
  key: 0,
  class: "flex flex-col gap-2"
}
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { key: 1 }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["onClick"]
const _hoisted_20 = {
  key: 1,
  class: "text-center font-bold h-[50px] mt-12 w-full"
}

import { ref, onMounted, watch, toRef } from 'vue'
import Agent from '@/composables/admin/agent'
import Corporate from '@/composables/admin/corporate'
import { handleApiError, isLoggedIn, isAdminLoggedIn, saveBookingNumber, isMakePaymentVisible, formattedDateTime, logout } from '@/utils/common'
import SlideOver from '@/widgets/SlideOver.vue'
import BookingDetails from '@/components/admin/BookingDetails.vue'
import { STATUSES } from '@/utils/constants'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import { saveUserSearch } from '@/services/search-service'
import CarService from '@/services/car-service'
import Admin from '@/composables/admin'
import router from '@/router'


export default /*@__PURE__*/_defineComponent({
  __name: 'BookingsTable',
  props: ['subAgents', 'bookings', 'isLoading', 'exportAs'],
  setup(__props) {

const { saveSelectedCar } = CarService()
const bookingData = ref<any>(null)
const subAgentsData = ref([])
const openDialog = ref<boolean>(false)
const selectedBooking = ref<any>(null)

const props = __props

onMounted(async () => {
   bookingData.value = props.bookings
   subAgentsData.value = props.subAgents
})

watch(() => props.isLoading, (newValue, oldValue) => {
   bookingData.value = props.bookings
   subAgentsData.value = props.subAgents
})

const handleBookingDetails = (booking: any) => {

   selectedBooking.value = booking
   openDialog.value = true
}

const getStatusClass = (status: string) => {

   if (status == 'incomplete') {
      return 'bg-theme-button4-bg text-theme-button4-txt'
   } else if (status == 'confirmed') {
      return 'bg-theme-button1-bg text-theme-button1-txt'
   } else if (status == 'awaiting_payment') {
      return 'bg-theme-button2-bg text-theme-button2-txt'
   } else if (status == 'need_confirmation') {
      return 'bg-theme-button3-bg text-theme-button3-txt'
   } else if (status == 'cancelled') {
      return 'bg-theme-button5-bg text-theme-button5-txt'
   } else if (status == 'unconfirmed') {
      return 'bg-theme-button3-bg text-theme-button3-txt'
   } else {
      return 'bg-theme-button-bg text-theme-button-txt'
   }
}

const filters = ref()
const statuses = STATUSES.map(status => status.title)
const dt = ref()

const initFilters = () => {
   filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      number: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      bookedBy: { value: null, matchMode: FilterMatchMode.IN },
   }
}

initFilters()

const clearFilter = () => {
   initFilters()
}

const exportCSV = () => {

   const allData = dt.value.filteredValue || dt.value.value || []
   const bookingCount = allData.length
   const salesTotal = allData.reduce((sum: number, record: any) => sum + (parseFloat(record.totalAmount) || 0), 0)

   const headers = [
      'Booking Number',
      'Customer Name',
      'Customer Phone',
      'Booked Car',
      'Booked By',
      'Booked At',
      'Pick up',
      'Pick up Date',
      'Pick up Time',
      'Status',
      'Booking Total',
      'Commission',
   ].join(',')

   const rows = allData.map((record : any) =>
      [
         record.number.replace(/,/g, ' ') || '',
         record.contact?.name?.replace(/,/g, ' ') || '',
         record.contact?.phone?.replace(/,/g, ' ') || '',
         record.car?.name?.replace(/,/g, ' ') || '',
         record.bookedBy?.name?.replace(/,/g, ' ') || 'N/A',
         record.createdAt ? formattedDateTime(record.createdAt).replace(/,/g, ' ') : 'N/A',
         record.search?.pickUp?.location?.name?.replace(/,/g, ' ') || '',
         record.search?.pickUp?.date?.replace(/,/g, ' ') || '',
         record.search?.pickUp?.time?.replace(/,/g, ' ') || '',
         record.status?.replace(/,/g, ' ') || '',
         record.totalAmount?.replace(/,/g, ' ') || 'N/A',
         `${record.commission?.amount?.toFixed(2)} (${record.commission?.percent}%)` || 'N/A',
      ].join(',')
   )

   rows.push("")
   rows.push(`Total Bookings, ${bookingCount}`)
   rows.push(`Total Sales, ${salesTotal.toFixed(2)}`)

   const csvContent = [headers, ...rows].join('\n')
   const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
   const link = document.createElement('a')
   link.href = URL.createObjectURL(blob)
   link.download = props.exportAs && props.exportAs != '' ? `${props.exportAs}.csv` : 'report.csv'
   link.click()
}

const makeBookingPayment = (booking: any) => {

   saveUserSearch(booking.search)
   saveBookingNumber(booking.number)
   saveSelectedCar(booking.car)
   router.push({name: 'BookingWizard', params: {step: 'contact-details'}})
}


return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (!__props.isLoading)
    ? (_openBlock(), _createBlock(_component_DataTable, {
        key: 0,
        exportFilename: "bookings",
        ref_key: "dt",
        ref: dt,
        class: "text-sm",
        filters: filters.value,
        "onUpdate:filters": _cache[3] || (_cache[3] = ($event: any) => ((filters).value = $event)),
        value: bookingData.value,
        paginator: "",
        showGridlines: "",
        rows: 10,
        dataKey: "id",
        filterDisplay: "menu",
        loading: __props.isLoading,
        globalFilterFields: [
         'name', 
         'status', 
         'search.pickUp.date', 
         'search.pickUp.time', 
         'contact.name', 
         'contact.email', 
         'contact.phone',
         'bookedBy.name',
         'createdAt',
      ]
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, [
              _createVNode(_component_InputText, {
                modelValue: filters.value['global'].value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filters.value['global'].value) = $event)),
                placeholder: "Keyword Search"
              }, null, 8, ["modelValue"])
            ]),
            _createVNode(_component_Button, {
              type: "button",
              icon: "pi pi-filter-slash",
              label: "Clear",
              outlined: "",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (clearFilter()))
            }),
            _createVNode(_component_Button, {
              icon: "pi pi-external-link",
              label: "Export",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (exportCSV()))
            })
          ])
        ]),
        empty: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode(" No bookings found. ")
        ])),
        loading: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode(" Loading bookings data. Please wait. ")
        ])),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "number",
            header: "Number",
            exportHeader: "Booking Number"
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.number), 1)
            ]),
            filter: _withCtx(({ filterModel }) => [
              _createVNode(_component_InputText, {
                modelValue: filterModel.value,
                "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                type: "text",
                class: "p-column-filter",
                placeholder: "Search booking number"
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: "Pick up",
            field: "search.pickUp",
            exportable: false,
            style: {"min-width":"8rem"}
          }, {
            body: _withCtx(({ data }) => [
              (data.search && data.search.pickUp)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                    _createTextVNode(_toDisplayString(data.search.pickUp.date) + " ", 1),
                    _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(" " + _toDisplayString(data.search.pickUp.time), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "search.pickUp.location.name",
            exportHeader: "Pick up",
            class: "hidden"
          }, {
            body: _withCtx(({ data }) => [
              (data.search && data.search.pickUp && data.search.pickUp.location)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(data.search.pickUp.location.name), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: "Booked Car",
            field: "car.name"
          }),
          _createVNode(_component_Column, {
            field: "bookedBy.name",
            exportHeader: "Booked By",
            class: "hidden"
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.bookedBy ? data.bookedBy.name : 'N/A'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "contact.name",
            exportHeader: "Customer Name",
            class: "hidden"
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.contact.name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "contact.phone",
            exportHeader: "Customer Phone",
            class: "hidden"
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.contact.phone), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: "Customer Info",
            field: "contact",
            exportable: false
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", null, _toDisplayString(data.contact.name), 1),
                (data.contact.name)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _cache[7] || (_cache[7] = _createElementVNode("i", {
                        class: "pi pi-phone",
                        style: {"font-size":"0.7rem"}
                      }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(data.contact.phone), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: "Booked By",
            filterField: "bookedBy",
            showFilterMatchModes: false,
            filterMenuStyle: { width: '14rem' },
            style: {"min-width":"14rem"}
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_7, [
                (data.bookedBy)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "rounded-full w-[32px] h-[32px]",
                      alt: data.bookedBy ? data.bookedBy.name : '',
                      src: data.bookedBy.photo ? data.bookedBy.photo : '/assets/avatars/default.jpg'
                    }, null, 8, _hoisted_8))
                  : _createCommentVNode("", true),
                _createElementVNode("span", null, _toDisplayString(data.bookedBy ? data.bookedBy.name : 'N/A'), 1)
              ])
            ]),
            filter: _withCtx(({ filterModel }) => [
              _createVNode(_component_MultiSelect, {
                modelValue: filterModel.value,
                "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                options: subAgentsData.value,
                optionLabel: "name",
                placeholder: "Any",
                class: "p-column-filter"
              }, {
                option: _withCtx((slotProps) => [
                  _createElementVNode("span", _hoisted_9, [
                    _createElementVNode("img", {
                      class: "rounded-full w-[32px] h-[32px]",
                      alt: slotProps.option.name,
                      src: slotProps.option.photo
                    }, null, 8, _hoisted_10),
                    _createElementVNode("span", null, _toDisplayString(slotProps.option.name), 1)
                  ])
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "options"])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: "Booked At",
            exportHeader: "Booked At",
            style: {"min-width":"8rem"}
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("span", null, _toDisplayString(data.createdAt ? _unref(formattedDateTime)(data.createdAt) : 'N/A'), 1)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "search.pickUp.date",
            exportHeader: "Pick up date",
            class: "hidden"
          }, {
            body: _withCtx(({ data }) => [
              (data.search && data.search.pickUp)
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(data.search.pickUp.date), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "search.pickUp.time",
            exportHeader: "Pick up time",
            class: "hidden"
          }, {
            body: _withCtx(({ data }) => [
              (data.search && data.search.pickUp)
                ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(data.search.pickUp.time), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "totalAmount",
            header: "Booking Total",
            style: {"min-width":"10rem"}
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.totalAmount ? data.totalAmount : 'N/A'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            field: "commission.amount",
            header: "Commission",
            style: {"min-width":"8rem"}
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.commission && data.commission.amount ? `${data.commission.amount.toFixed(2)} (${data.commission.percent}%)`  : 'N/A'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: "Status",
            field: "status"
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", {
                class: _normalizeClass([getStatusClass(data.status), "rounded rounded-bg text-center min-w-[85px] py-2 uppercase text-xs"])
              }, _toDisplayString(data.status), 3)
            ]),
            filter: _withCtx(({ filterModel }) => [
              _createVNode(_component_Dropdown, {
                modelValue: filterModel.value,
                "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                options: _unref(statuses),
                placeholder: "Select One",
                class: "p-column-filter",
                showClear: ""
              }, {
                option: _withCtx((slotProps) => [
                  _createTextVNode(_toDisplayString(slotProps.option), 1)
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "options"])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: "Actions",
            exportable: false,
            class: "w-[12rem]"
          }, {
            body: _withCtx(({ data }) => [
              (data.status == 'confirmed' || data.status == 'cancelled')
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("div", {
                      onClick: ($event: any) => (_unref(router).push({name: 'UserBookingVoucher', params: {number: data.number}})),
                      class: "bg-theme-button1-bg text-theme-button1-txt text-center rounded w-[80px] py-1 cursor-pointer"
                    }, " Voucher ", 8, _hoisted_15),
                    _createElementVNode("div", {
                      onClick: ($event: any) => (_unref(router).push({name: 'UserBookingInvoice', params: {number: data.number}})),
                      class: "bg-theme-button3-bg text-theme-button3-txt text-center rounded w-[80px] py-1 cursor-pointer"
                    }, " Invoice ", 8, _hoisted_16)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("div", {
                      onClick: ($event: any) => (_unref(router).push({name: 'UserBookingDetails', params: {number: data.number}})),
                      class: "bg-theme-button2-bg text-theme-button2-txt text-center rounded w-[80px] py-1 cursor-pointer"
                    }, " Details ", 8, _hoisted_18)
                  ])),
              (data.status != 'confirmed' && data.status != 'need_confirmation' && data.status != 'unconfirmed' && data.search && data.search.pickUp && _unref(isMakePaymentVisible)(data.search.pickUp.date))
                ? (_openBlock(), _createElementBlock("span", {
                    key: 2,
                    onClick: ($event: any) => (makeBookingPayment(data)),
                    class: "text-indigo-500 hover:text-indigo-600 cursor-pointer"
                  }, " | Make Payment ", 8, _hoisted_19))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["filters", "value", "loading"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_20, " Loading data please wait... "))
}
}

})