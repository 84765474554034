import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col bg-theme-gray-1 px-4 py-8" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col divide-y divide-theme-gray-9 gap-5"
}
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col gap-2 pt-4"
}
const _hoisted_4 = {
  key: 0,
  class: "flex items-center gap-2"
}
const _hoisted_5 = { class: "text-theme-gray-5 text-size-3" }
const _hoisted_6 = {
  key: 1,
  class: "flex items-center gap-2"
}
const _hoisted_7 = { class: "text-theme-gray-5 text-size-3" }
const _hoisted_8 = {
  key: 2,
  class: "flex items-center gap-2"
}
const _hoisted_9 = { class: "text-theme-gray-5 text-size-3" }
const _hoisted_10 = {
  key: 3,
  class: "flex items-center gap-2"
}
const _hoisted_11 = { class: "text-theme-gray-5 text-size-3" }
const _hoisted_12 = {
  key: 4,
  class: "font-bold text-sm text-theme-active"
}
const _hoisted_13 = { class: "flex flex-col gap-2 pt-4" }
const _hoisted_14 = { class: "text-theme-gray-5 text-size-3 capitalize" }
const _hoisted_15 = { class: "flex flex-col gap-2 pt-4" }
const _hoisted_16 = { class: "text-theme-gray-5 text-size-3" }
const _hoisted_17 = { class: "flex flex-col gap-2 pt-4" }
const _hoisted_18 = { class: "hidden flex flex-col gap-2 pt-4" }
const _hoisted_19 = { class: "text-theme-gray-5 text-size-3 capitalize" }
const _hoisted_20 = {
  key: 1,
  class: "flex flex-col gap-2 pt-4"
}
const _hoisted_21 = { class: "text-theme-gray-5 text-size-3" }
const _hoisted_22 = {
  key: 2,
  class: "flex flex-row items-start pt-4 gap-2"
}
const _hoisted_23 = { class: "flex-1 flex flex-col gap-2" }
const _hoisted_24 = { class: "text-theme-gray-5 text-size-3" }
const _hoisted_25 = { class: "flex-1 flex flex-col gap-2" }
const _hoisted_26 = { class: "text-theme-gray-5 text-size-3" }

import { onMounted, ref } from 'vue'
import { retrieveUserSearch } from '@/services/search-service' 
import { UserSearch, Car as CarData, LocationSchedule } from '@/types'
import { getSessionPrefix, getHoursPerDay } from '@/utils/common'
import GoogleMap from '@/components/GoogleMap.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'BookingSummary',
  props: ['currentStep'],
  setup(__props) {

const props = __props
const userSearch = ref<UserSearch | null>(null)
const selectedCar = ref<CarData | null>(null)
const distance = ref<string>('0 km')
const time = ref<string>('0h 0m')

const handleMapData = (value: string, type: string) => {
   if (type == 'distance') {
      distance.value = value
   } else if(type == 'time') {
      time.value = value
   }
}

const handlePickUpClick = () => {
 
   const locationName = userSearch.value?.pickUp.location.name as string
   const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(locationName)}`
   window.open(googleMapsUrl, '_blank')
}  

onMounted(() => {

   userSearch.value = retrieveUserSearch()
   selectedCar.value = retrieveSelectedVehicle()
})

const retrieveSelectedVehicle = () : CarData => {

   const prefix = getSessionPrefix()
   const carString = localStorage.getItem(`${prefix}_selected_car`)

   return carString ? JSON.parse(carString) : null
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[12] || (_cache[12] = _createElementVNode("div", { class: "text-theme-gray-5 text-size-6 font-bold mb-3" }, "Summary", -1)),
    (userSearch.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (selectedCar.value && __props.currentStep?.uri == 'contact-details')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Vehicle selected", -1)),
                (selectedCar.value.type)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-theme-gray-5 text-size-3 capitalize" }, "Type: ", -1)),
                      _createElementVNode("div", _hoisted_5, _toDisplayString(selectedCar.value.type.name), 1)
                    ]))
                  : _createCommentVNode("", true),
                (userSearch.value.type == 'hourly' && selectedCar.value.dailyrate)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-theme-gray-5 text-size-3 capitalize" }, "Rate Per Day: ", -1)),
                      _createElementVNode("div", _hoisted_7, _toDisplayString(selectedCar.value.dailyrate?.toFixed(2)) + " USD ", 1)
                    ]))
                  : _createCommentVNode("", true),
                (userSearch.value.type == 'hourly' && userSearch.value.hours)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-theme-gray-5 text-size-3 capitalize" }, "No of days: ", -1)),
                      _createElementVNode("div", _hoisted_9, _toDisplayString(userSearch.value.hours / _unref(getHoursPerDay)()), 1)
                    ]))
                  : _createCommentVNode("", true),
                (selectedCar.value.price && selectedCar.value.price > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-theme-gray-5 text-size-3 capitalize" }, "Total Price: ", -1)),
                      _createElementVNode("div", _hoisted_11, _toDisplayString(selectedCar.value.price.toFixed(2)) + " USD ", 1)
                    ]))
                  : _createCommentVNode("", true),
                (selectedCar.value.price && selectedCar.value.price > 0 && userSearch.value.type == 'hourly')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("p", null, "Note: Rate is for " + _toDisplayString(_unref(getHoursPerDay)()) + " continuous hours within the city limits of " + _toDisplayString(selectedCar.value.chaufferLocation?.locality.name), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_13, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Service type", -1)),
            _createElementVNode("div", _hoisted_14, _toDisplayString(userSearch.value.type == 'hourly' ? 'Chauffeur' : 'Transfer'), 1)
          ]),
          _createElementVNode("div", _hoisted_15, [
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Pickup date & time", -1)),
            _createElementVNode("div", _hoisted_16, _toDisplayString(userSearch.value.pickUp.date) + ", " + _toDisplayString(userSearch.value.pickUp.time), 1)
          ]),
          _createElementVNode("div", _hoisted_17, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Pickup location", -1)),
            _createElementVNode("div", {
              onClick: handlePickUpClick,
              class: "text-theme-gray-5 text-size-3 hover:underline cursor-pointer"
            }, _toDisplayString(userSearch.value.pickUp.location.name), 1),
            _createVNode(GoogleMap, {
              origin: userSearch.value.pickUp.location.id,
              destination: userSearch.value.dropOff.location.id,
              driveType: userSearch.value.type,
              onDistance: handleMapData,
              onTime: handleMapData
            }, null, 8, ["origin", "destination", "driveType"])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Transfer type", -1)),
            _createElementVNode("div", _hoisted_19, _toDisplayString(userSearch.value.mode), 1)
          ]),
          (userSearch.value.type == 'distance')
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Drop-off location", -1)),
                _createElementVNode("div", _hoisted_21, _toDisplayString(userSearch.value.dropOff.location.name), 1)
              ]))
            : _createCommentVNode("", true),
          (userSearch.value.type == 'distance')
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, [
                  _cache[10] || (_cache[10] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Total distance", -1)),
                  _createElementVNode("div", _hoisted_24, _toDisplayString(distance.value), 1)
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _cache[11] || (_cache[11] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Total time", -1)),
                  _createElementVNode("div", _hoisted_26, _toDisplayString(time.value), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})