import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, unref as _unref, vModelRadio as _vModelRadio, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-12 md:gap-10" }
const _hoisted_2 = {
  key: 0,
  class: "col-span-12 md:col-span-3 flex flex-col h-fit order-2 md:order-1 mt-12 md:mt-0"
}
const _hoisted_3 = {
  key: 1,
  class: "challenge-form mx-auto col-span-12 md:col-span-12 flex flex-col justify-center order-1 md:order-2 py-12"
}
const _hoisted_4 = ["action"]
const _hoisted_5 = {
  key: 2,
  class: "col-span-12 md:col-span-9 flex flex-col order-1 md:order-2"
}
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7" }
const _hoisted_8 = { class: "col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5" }
const _hoisted_9 = {
  key: 0,
  class: "text-theme-error"
}
const _hoisted_10 = { class: "col-span-2 md:col-span-1" }
const _hoisted_11 = { class: "col-span-1 flex flex-col gap-2 items-start px-4 py-5" }
const _hoisted_12 = {
  key: 0,
  class: "text-theme-error"
}
const _hoisted_13 = { class: "grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7" }
const _hoisted_14 = { class: "col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5" }
const _hoisted_15 = {
  key: 0,
  class: "text-theme-error"
}
const _hoisted_16 = { class: "col-span-2 md:col-span-1" }
const _hoisted_17 = { class: "col-span-1 flex flex-col gap-2 items-start px-4 py-5 relative" }
const _hoisted_18 = {
  key: 0,
  class: "text-theme-error"
}
const _hoisted_19 = { class: "grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7" }
const _hoisted_20 = { class: "col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5" }
const _hoisted_21 = { class: "col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { class: "flex flex-col" }
const _hoisted_24 = { class: "grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7" }
const _hoisted_25 = { class: "col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5" }
const _hoisted_26 = {
  key: 1,
  class: "flex flex-col mt-6"
}
const _hoisted_27 = {
  key: 2,
  class: "flex flex-col"
}
const _hoisted_28 = { class: "divide-x divide-gray-200 flex items-start gap-4 overflow-x-auto border border-gray-200 saved-cards" }
const _hoisted_29 = { class: "relative pl-4 flex min-w-[50%] md:min-w-[25%] items-start pb-4 pt-3.5" }
const _hoisted_30 = { class: "mr-3 flex h-6 items-center" }
const _hoisted_31 = {
  key: 0,
  class: "relative pl-4 h-full flex min-w-[50%] md:min-w-[25%] items-start pb-4 pt-3.5"
}
const _hoisted_32 = { class: "mr-3 flex h-6 items-center" }
const _hoisted_33 = { class: "min-w-0 flex-1 text-sm leading-6" }
const _hoisted_34 = { class: "text-gray-500" }
const _hoisted_35 = { class: "hidden apple_pay_button relative pl-4 flex flex-col gap-2 min-w-[50%] md:min-w-[25%] items-start pb-4 pt-3.5 h-full" }
const _hoisted_36 = { class: "flex" }
const _hoisted_37 = { class: "mr-3 flex h-6 items-center" }
const _hoisted_38 = { class: "mr-3 flex h-6 items-center" }
const _hoisted_39 = ["value", "id", "aria-describedby"]
const _hoisted_40 = { class: "min-w-0 flex-1 text-sm leading-6" }
const _hoisted_41 = ["for"]
const _hoisted_42 = ["id"]
const _hoisted_43 = {
  key: 0,
  class: "mt-1 flex h-6 items-center"
}
const _hoisted_44 = {
  key: 3,
  class: "flex flex-col mt-6"
}
const _hoisted_45 = {
  key: 4,
  class: "flex flex-col"
}
const _hoisted_46 = { class: "grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7" }
const _hoisted_47 = { class: "col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5" }
const _hoisted_48 = {
  key: 0,
  class: "text-theme-error"
}
const _hoisted_49 = { class: "col-span-2 md:col-span-1" }
const _hoisted_50 = { class: "col-span-1 flex flex-col gap-2 items-start px-4 py-5" }
const _hoisted_51 = {
  key: 0,
  class: "text-theme-error"
}
const _hoisted_52 = { class: "grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7" }
const _hoisted_53 = { class: "col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5" }
const _hoisted_54 = {
  key: 0,
  class: "text-theme-error"
}
const _hoisted_55 = { class: "col-span-2 md:col-span-1" }
const _hoisted_56 = { class: "col-span-1 flex flex-col gap-2 items-start px-4 py-5" }
const _hoisted_57 = {
  key: 0,
  class: "text-theme-error"
}
const _hoisted_58 = {
  key: 0,
  class: "text-theme-error mt-4"
}
const _hoisted_59 = {
  key: 5,
  class: "flex flex-col mt-4 text-theme-gray-3 text-sm"
}
const _hoisted_60 = { class: "flex flex-col md:flex-row items-center mt-6" }
const _hoisted_61 = { class: "text-theme-gray-2" }
const _hoisted_62 = { class: "gap-4 mt-12 md:ml-auto flex flex-col md:flex-row md:mt-auto uppercase w-full md:w-auto items-center" }

import { ref, onMounted, nextTick } from 'vue'
import BookingSummary from '@/components/BookingSummary.vue'
import CountrySearchablePhoneField from '@/components/CountrySearchablePhoneField.vue'
import { getBackendBaseUrl, getBookingNumber, isValidCardNumber, isValidEmail, resetErrors, savePaymentSession, savePaymentTransaction, savePaymentCode, isLoggedIn, loadScript, handleApiError, savePaymentWallet, canPayViaCredit, getAuthUserAccountType } from '@/utils/common'
import CarService from '@/services/car-service'
import { Car as CarData, SavedCardType, UserSearch, CreditType } from '@/types'
import { retrieveUserSearch } from '@/services/search-service'
import BookingService from '@/services/booking-service'
import Payment from '@/composables/Payment'
import { LOADER_MINIMUM_TIME, credimax, APPLE_PAY_MERCHANT } from '@/utils/constants'
import { useRouter } from 'vue-router'
import creditCardType from 'credit-card-type'
import Car from '@/composables/Car'
import { NotificationType } from '@/types'
import Notification from '@/widgets/Notification.vue'
import Loader from '@/widgets/Loader.vue'
import SavedCard from '@/composables/SavedCard'
import BookingCreditService from '@/services/booking-credit-service'
import ApplePay from '@/composables/ApplePay'
// @ts-ignore
import $ from 'jquery'

declare global {
  interface Window {
    ThreeDS: any;
    ApplePaySession: any;
  }
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactDetails-back',
  props: ['currentStep'],
  setup(__props) {

const props = __props
const { retrieveUserSelectedCar } = CarService()
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const { createSession, updateSession, createToken, initiateAuthentication, authenticatePayer  } = Payment()
const { savedCardList  } = SavedCard()
const { updateBooking } = BookingService()
const { getCredit } = BookingCreditService()
const logs = ref<any>([])
const outputToken = ref<string>('')
const router = useRouter()
const authUser = isLoggedIn()
const contactDetails = ref<any>({
   firstName: '',
   phoneNumber: '',
   flightNumber: '',
   lastName: '',
   email: '',
   comments: '',
   needConfirmation: false
})
const bookingRefNo = ref<string | null>(null)
const paymentDetails = ref<any>({
   card: {
      name: '',
      number: '',
      cvc: '',
      expiration: ''
   },
   token: '',
   session: null,
   order: null,
   transaction: null,
   cardOption: ''
})
const errors = ref<any>({
   firstName: null,
   lastName: null,
   email: null,
   phoneNumber: null,
   cardName: null,
   cardNumber: null,
   cardCVC: null,
   cardExpiration: null,
   responseError: null
})
const accountType = getAuthUserAccountType()
const isLoading = ref<boolean>(false)
const initiateResponsee = ref<any>(null)
const authenticatePayerResponsee = ref<any>(null)
const car : CarData = retrieveUserSelectedCar()
const search : UserSearch = retrieveUserSearch()
const html = ref<any>({
   formAction: null,
   fieldValue: ''
})
const timeElapsed = ref<number>(0)
const savedCards = ref<SavedCardType[] | null>(null)
const credit = ref<CreditType | null>(null)
const hideElements = ref<boolean>(false)
const { validateMerchant, authorizePayment } = ApplePay()

const handleBookingProceed = async () => {
   
   hideElements.value = false
   resetErrors(errors.value)
   let isValid = validateForm()

   if (!isLoading.value && isValid) {

      if (!car || !search) {
         console.log('Error in data collection: ', car, search)
      }

      const bookingNumber = getBookingNumber() as string
      isLoading.value = true
      let tokenResponse

      if (contactDetails.value.needConfirmation == false) {

         paymentDetails.value.order = bookingNumber
         if (paymentDetails.value.cardOption == 'new') {
            paymentDetails.value.card.brand = getCardBrand(paymentDetails.value.card.number.toString())
            tokenResponse = await createToken(paymentDetails.value)
         } else if(paymentDetails.value.cardOption == 'apple_pay') {
            tokenResponse = { status: 'apple_pay' }
         } else if(paymentDetails.value.cardOption == 'credit') {
            tokenResponse = { status: 'credit' }
         } else {
            paymentDetails.value.card.brand = ''
            tokenResponse = { status: 201, data: { token : getSavedCardToken(paymentDetails.value.cardOption) } }
         }
         
         if (tokenResponse.status == 201) {
            const sessionResponse = await updateSession(paymentDetails.value.session, tokenResponse.data.token, bookingNumber as string)
            paymentDetails.value.token = tokenResponse.data.token
            
            if (sessionResponse.status == 200) {
               paymentDetails.value.transaction = sessionResponse.data.transaction
               savePaymentTransaction(paymentDetails.value.transaction)
               
               const initiateResponse = await initiateAuthentication(paymentDetails.value.session, bookingNumber, paymentDetails.value.transaction)
               initiateResponsee.value = JSON.stringify(initiateResponse.data.response)
               
               if (initiateResponse.data.response.transaction.authenticationStatus == 'AUTHENTICATION_AVAILABLE'){
                  const authenticatePayerResponse = await authenticatePayer(paymentDetails.value.session, bookingNumber, `${paymentDetails.value.transaction}`)
                  authenticatePayerResponsee.value = JSON.stringify(authenticatePayerResponse.data.response)
                  const response = authenticatePayerResponse.data.response
                  if (response && response.response && response.response.gatewayRecommendation == 'PROCEED') {
                     const action = response.authentication.redirect.customizedHtml['3ds2'].acsUrl
                     const inputValue = response.authentication.redirect.customizedHtml['3ds2'].cReq
                     html.value = {
                        formAction: action,
                        fieldValue: inputValue
                     }
                     
                     await nextTick()
                     const form = document.getElementById('threedsChallengeRedirectForm') as HTMLFormElement | null
                     if (form) {
                        form.submit()
                        setTimeout(() => isLoading.value = false, 2000)
                        hideElements.value = true
                        $('.booking-steps, .main-header, .main-footer').hide()
                     }
                  } else {
                     errors.value.responseError = 'payer authentication request finished but returned a different than expected response'
                     isLoading.value = false
                  }
               } else {
                  errors.value.responseError = 'initiate authentication request finished but returned a different than expected response'
                  isLoading.value = false
               }
            } else {
               errors.value.responseError = JSON.stringify(sessionResponse)
               isLoading.value = false
            }
         } else if (tokenResponse.status == 'apple_pay') {
            initiateApplePay()
            isLoading.value = false
         } else if (tokenResponse.status == 'credit') {
            await initiateCreditPayment()
         } else {
            errors.value.responseError = JSON.stringify(tokenResponse)
            isLoading.value = false
         }
      } else {
         
         const startTime = performance.now()
         const response = await updateBooking(bookingNumber, contactDetails.value, bookingRefNo.value, paymentDetails.value, true)
         timeElapsed.value = performance.now() - startTime

         if (timeElapsed.value < LOADER_MINIMUM_TIME) {
            const diff = LOADER_MINIMUM_TIME - timeElapsed.value
            setTimeout(() => {
               if (response.success) {
                  router.push({ name: 'BookingWizard', params: { step: 'finish' } })
               }
            }, diff)
         } else {
            if (response.success) {
               router.push({ name: 'BookingWizard', params: { step: 'finish' } })
            }
         }
      }
   }
}

const validateForm = () => {
   
   let response = true
   
   if (contactDetails.value.firstName === '') {
      errors.value.firstName = true
      response = false
   }
   
   if (contactDetails.value.lastName === '') {
      errors.value.lastName = true
      response = false
   }

   if (contactDetails.value.phoneNumber === '') {
      errors.value.phoneNumber = 'Required'
      response = false
   }else if (contactDetails.value.phoneNumber.length < 11) {
      errors.value.phoneNumber = 'Invalid'
      response = false
   }

   if (contactDetails.value.email === '') {
      errors.value.email = 'Required'
      response = false
   } else if (!isValidEmail(contactDetails.value.email)) {
      errors.value.email = 'Invalid'
      response = false
   }
   
   if (contactDetails.value.needConfirmation === false && paymentDetails.value.cardOption == 'new') {
      
      if (paymentDetails.value.card.name === '') {
         errors.value.cardName = true
         response = false
      }
   
      if (paymentDetails.value.card.number === '') {
         errors.value.cardNumber = 'Required'
         response = false
      } else if (!isValidCardNumber(paymentDetails.value.card.number)) {
         errors.value.cardNumber = 'Invalid'
         response = false
      }
   
      if (paymentDetails.value.card.expiration === '') {
         errors.value.cardExpiration = true
         response = false
      }
   } else if (contactDetails.value.needConfirmation === false && paymentDetails.value.cardOption !== 'apple_pay' && paymentDetails.value.cardOption !== 'credit') {

      if (paymentDetails.value.card.cvc == '') {
         errors.value.cardCVC = true
         response = false
      }
   }

   return response
}

const handlePhoneChange = (value: string) => {
   contactDetails.value.phoneNumber = value
}

const getCardBrand = (cardNumber: string) => {
   const cardType = creditCardType(cardNumber);

   if (cardType.length > 0) {
      return cardType[0].niceType
   }

   return 'Unknown'
}

const getSavedCardToken = (cardId: number) : string | null => {

   const card = savedCards.value?.find((card: SavedCardType) => card.id === cardId)
   return card ? card.token : null
}

const checkAndCreateSession = async () : Promise<{status: boolean, session: string | null}> => {

   const sessionResponse = await createSession()
   if (sessionResponse.status == 200) {
      savePaymentSession(sessionResponse.data.session)
      return {status: true, session: sessionResponse.data.session}
   } else {
      return {status: false, session: null}
   }
}

onMounted(async () => {

   const response: any = retrieveUserSelectedCar()
   if (response && response.price === null) {
      contactDetails.value.needConfirmation = true
   }

   const savedCardsResponse = await savedCardList()
   if (savedCardsResponse && savedCardsResponse.status == 200 && savedCardsResponse.data.cards.length) {
      savedCards.value = savedCardsResponse.data.cards
      paymentDetails.value.cardOption = savedCardsResponse.data.cards[savedCardsResponse.data.cards.length-1].id
   } else {
      paymentDetails.value.cardOption = 'new'
   }

   const creditResponse = await getCredit(getBookingNumber())
   if (creditResponse && creditResponse.success) {
      credit.value = creditResponse.data.credit
   }

   showApplePayButton()

   const sessionResponse = await checkAndCreateSession()
   if (!sessionResponse.status) {
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: 'Payment cannot be processed at the moment'}
   } else {
      paymentDetails.value.session = sessionResponse.session
   }
})

const showApplePayButton = () => {
   
   loadScript('https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js', function () {
      if ((window as any).ApplePaySession) {
         var promise = (window as any).ApplePaySession.canMakePaymentsWithActiveCard(APPLE_PAY_MERCHANT)
         promise.then(function (canMakePayments: any) {
            
            if (canMakePayments) {
               $('.apple_pay_button').removeClass('hidden')
            } else {
               alert('You dont have an active card connected to apple pay')
            }
         })
      }
   })
}

const notifClosed = () : void => {
   notif.value.show = false
}

window.addEventListener('message', function(event) {

   const backendUrl = getBackendBaseUrl()
   if (backendUrl.includes(event.origin) && event.data === 'redirect') {
      
      (async () => {
         const bookingNumber = getBookingNumber() as string
         savePaymentCode(paymentDetails.value.card.cvc)
         savePaymentWallet('card')
         const payDetails = {
            card: {
               name: paymentDetails.value.card.name,
               number: paymentDetails.value.card.number.toString().slice(-4),
               brand: paymentDetails.value.card.brand
            },
            token: paymentDetails.value.token
         }
         const response = await updateBooking(bookingNumber, contactDetails.value, bookingRefNo.value, payDetails, false)
         if (response.success) {
            router.push({ name: 'BookingWizard', params: { step: 'finish' } })
         } else {
            console.log(response.error)
         }
      })()
   } else if (backendUrl.includes(event.origin) && event.data === 'error') {

      location.reload()
   }
})

const initiateApplePay = () => {
   
   const total = car.price.toFixed(2)
   if ((window as any).ApplePaySession) {
      
      const paymentRequest = {
         countryCode: 'BH',
         currencyCode: 'USD',
         total: {
            label: 'Vehitra World Payment',
            type: "final",
            amount: total,
         },
         supportedNetworks: [
            "visa",
            "mada",
            "masterCard",
            "amex"
         ],
         merchantCapabilities: [
            "supports3DS"
         ]
      }

      try {
         const session = new (window as any).ApplePaySession(12, paymentRequest)
         session.begin()

         session.onvalidatemerchant = async (event: any) => {

            const validateResponse = await validateMerchant(event.validationURL)

            if (validateResponse.status == 200) {

               console.log(JSON.parse(validateResponse.data))
               session.completeMerchantValidation(JSON.parse(validateResponse.data))
               console.log('merchant validation is successful')
            } else {

               const error = handleApiError(validateResponse)
               console.log(error)
            }
         }

         session.onpaymentmethodselected = (event: any) => {
            const update = {
               "newTotal": {
                  "label": "Click to pay Vehitra Tech",
                  "type": "final",
                  "amount": total
               }
            }

            session.completePaymentMethodSelection(update)
            console.log('on paymentmethod selected complete')
         }

         session.onshippingmethodselected = (event: any) => {

            const update = {}
            session.completeShippingMethodSelection(update)
         }

         session.onshippingcontactselected = (event: any) => {

            const update = {}
            session.completeShippingContactSelection(update)
         }

         session.onpaymentauthorized = async (event: any) => {

            let paymentToken = event.payment.token
            const bookingNumber = getBookingNumber() as string
            const response = await authorizePayment(JSON.stringify(paymentToken), bookingNumber, contactDetails.value)
            const result = {
               'status': response.data.success ? (window as any).ApplePaySession.STATUS_SUCCESS : (window as any).ApplePaySession.STATUS_FAILURE
            }
            session.completePayment(result)

            if (response.data.success) {
               
               savePaymentWallet('apple_pay')
               setTimeout(() => {
                  router.push({ name: 'BookingWizard', params: { step: 'finish' } })
               }, 500)
            }
         }

         session.oncancel = (event: any) => {
            console.log('payment cancelled')
         }

      } catch (error) {
         alert(error)
      }
      
   } else {
      console.error('Your device does not support apple pay')
   }
}

const initiateCreditPayment = async () => {
   
   const canPay = canPayViaCredit(credit.value as CreditType)
   if (canPay) {
      
      const bookingNumber = getBookingNumber() as string
      savePaymentWallet('credit')
      
      const startTime = performance.now()
      const response = await updateBooking(bookingNumber, contactDetails.value, bookingRefNo.value, paymentDetails.value, true)
      timeElapsed.value = performance.now() - startTime

      if (timeElapsed.value < LOADER_MINIMUM_TIME) {
         const diff = LOADER_MINIMUM_TIME - timeElapsed.value
         setTimeout(() => {
            if (response.success) {
               router.push({ name: 'BookingWizard', params: { step: 'finish' } })
            }
         }, diff)
      } else {
         if (response.success) {
            router.push({ name: 'BookingWizard', params: { step: 'finish' } })
         }
      }
   } else {
      errors.value.responseError = 'You cannot pay via credit'
      isLoading.value = false
   }
}


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[41] || (_cache[41] = _createElementVNode("div", { id: "3DSUI" }, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      (!hideElements.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(BookingSummary, { currentStep: __props.currentStep }, null, 8, ["currentStep"])
          ]))
        : _createCommentVNode("", true),
      (html.value.formAction && html.value.fieldValue != '')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("form", {
              id: "threedsChallengeRedirectForm",
              style: {"width":"100vh"},
              target: "challengeFrame",
              action: html.value.formAction,
              method: "post"
            }, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((html.value.fieldValue) = $event)),
                type: "hidden",
                name: "creq"
              }, null, 512), [
                [_vModelText, html.value.fieldValue]
              ]),
              _cache[17] || (_cache[17] = _createElementVNode("iframe", {
                id: "challengeFrame",
                name: "challengeFrame",
                style: {"width":"100%","min-height":"500px"},
                frameborder: "0"
              }, null, -1))
            ], 8, _hoisted_4)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _cache[39] || (_cache[39] = _createElementVNode("div", { class: "flex flex-col" }, [
              _createElementVNode("div", { class: "bg-theme-gray-7 px-5 py-3.5 text-theme-gray-2 text-size-2 uppercase" }, "Guest Contact details")
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", {
                    class: _normalizeClass([!errors.value.firstName ? 'text-theme-gray-2' : 'text-theme-error', "uppercase text-small flex items-center gap-1"])
                  }, [
                    _cache[18] || (_cache[18] = _createElementVNode("span", null, "First name *", -1)),
                    (errors.value.firstName)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, "(required)"))
                      : _createCommentVNode("", true)
                  ], 2),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((contactDetails.value.firstName) = $event)),
                    type: "text",
                    class: "outline-none w-full h-30 font-bold text-size-6 border-none"
                  }, null, 512), [
                    [_vModelText, contactDetails.value.firstName]
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", {
                      class: _normalizeClass([!errors.value.lastName ? 'text-theme-gray-2' : 'text-theme-error', "uppercase text-small flex items-center gap-1"])
                    }, [
                      _cache[19] || (_cache[19] = _createElementVNode("span", null, "Last name *", -1)),
                      (errors.value.lastName)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, "(required)"))
                        : _createCommentVNode("", true)
                    ], 2),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((contactDetails.value.lastName) = $event)),
                      type: "text",
                      class: "outline-none w-full h-30 font-bold text-size-6 border-none"
                    }, null, 512), [
                      [_vModelText, contactDetails.value.lastName]
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", {
                    class: _normalizeClass([!errors.value.email ? 'text-theme-gray-2' : 'text-theme-error', "uppercase text-small flex items-center gap-1"])
                  }, [
                    _cache[20] || (_cache[20] = _createElementVNode("span", null, "Email *", -1)),
                    (errors.value.email)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_15, "(" + _toDisplayString(errors.value.email) + ")", 1))
                      : _createCommentVNode("", true)
                  ], 2),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((contactDetails.value.email) = $event)),
                    type: "text",
                    class: "outline-none w-full h-30 font-bold text-size-6 border-none"
                  }, null, 512), [
                    [_vModelText, contactDetails.value.email]
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", {
                      class: _normalizeClass([!errors.value.phoneNumber ? 'text-theme-gray-2' : 'text-theme-error', "uppercase text-small flex items-center gap-1"])
                    }, [
                      _cache[21] || (_cache[21] = _createElementVNode("span", null, "Phone number *", -1)),
                      (errors.value.phoneNumber)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_18, "(" + _toDisplayString(errors.value.phoneNumber) + ")", 1))
                        : _createCommentVNode("", true)
                    ], 2),
                    _createVNode(CountrySearchablePhoneField, { onPhoneNumber: handlePhoneChange })
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _cache[22] || (_cache[22] = _createElementVNode("div", { class: "uppercase text-small text-theme-gray-2 flex items-center gap-1" }, [
                    _createElementVNode("span", null, "Flight Number (optional)")
                  ], -1)),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((contactDetails.value.flightNumber) = $event)),
                    type: "text",
                    class: "outline-none w-full h-30 font-bold text-size-6 border-none"
                  }, null, 512), [
                    [_vModelText, contactDetails.value.flightNumber]
                  ])
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _cache[23] || (_cache[23] = _createElementVNode("div", { class: "uppercase text-theme-gray-2 text-small" }, "Comments", -1)),
                  _withDirectives(_createElementVNode("textarea", {
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((contactDetails.value.comments) = $event)),
                    class: "outline-none w-full h-30 font-bold text-size-6 border-none h-[60px]"
                  }, null, 512), [
                    [_vModelText, contactDetails.value.comments]
                  ])
                ])
              ])
            ]),
            (_unref(accountType) == 'agent')
              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                  _cache[25] || (_cache[25] = _createElementVNode("div", { class: "flex flex-col mt-6" }, [
                    _createElementVNode("div", { class: "bg-theme-gray-7 px-5 py-3.5 text-theme-gray-2 text-size-2 uppercase" }, "Booking reference number")
                  ], -1)),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, [
                        _cache[24] || (_cache[24] = _createElementVNode("div", { class: "uppercase text-small text-theme-gray-2 flex items-center gap-1" }, [
                          _createElementVNode("span", null, "Enter Your Booking Reference Number (optional)")
                        ], -1)),
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((bookingRefNo).value = $event)),
                          type: "text",
                          class: "outline-none w-full h-30 font-bold text-size-6 border-none"
                        }, null, 512), [
                          [_vModelText, bookingRefNo.value]
                        ])
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            (!contactDetails.value.needConfirmation)
              ? (_openBlock(), _createElementBlock("div", _hoisted_26, _cache[26] || (_cache[26] = [
                  _createElementVNode("div", { class: "bg-theme-gray-7 px-5 py-3.5 text-theme-gray-2 text-size-2 uppercase" }, "Choose Payment Option", -1)
                ])))
              : _createCommentVNode("", true),
            (!contactDetails.value.needConfirmation)
              ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                  _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("div", _hoisted_30, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((paymentDetails.value.cardOption) = $event)),
                          value: "new",
                          id: "account-new",
                          "aria-describedby": "account-new-description",
                          type: "radio",
                          class: "h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                        }, null, 512), [
                          [_vModelRadio, paymentDetails.value.cardOption]
                        ])
                      ]),
                      _cache[27] || (_cache[27] = _createElementVNode("div", { class: "min-w-0 flex-1 text-sm leading-6" }, [
                        _createElementVNode("label", {
                          for: "account-new",
                          class: "font-medium text-gray-900 cursor-pointer"
                        }, "New Card")
                      ], -1))
                    ]),
                    (credit.value && _unref(canPayViaCredit)(credit.value as CreditType))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                          _createElementVNode("div", _hoisted_32, [
                            _withDirectives(_createElementVNode("input", {
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((paymentDetails.value.cardOption) = $event)),
                              value: "credit",
                              id: "use-credit",
                              "aria-describedby": "account-new-credit",
                              type: "radio",
                              class: "h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                            }, null, 512), [
                              [_vModelRadio, paymentDetails.value.cardOption]
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_33, [
                            _cache[28] || (_cache[28] = _createElementVNode("label", {
                              for: "use-credit",
                              class: "font-medium text-gray-900 cursor-pointer"
                            }, "Use Credit", -1)),
                            _createElementVNode("p", _hoisted_34, "Credit Balance: " + _toDisplayString(credit.value!.amount - credit.value!.used), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_35, [
                      _createElementVNode("div", _hoisted_36, [
                        _createElementVNode("div", _hoisted_37, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((paymentDetails.value.cardOption) = $event)),
                            id: "apple-pay",
                            value: "apple_pay",
                            type: "radio",
                            class: "h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                          }, null, 512), [
                            [_vModelRadio, paymentDetails.value.cardOption]
                          ])
                        ]),
                        _cache[29] || (_cache[29] = _createElementVNode("div", { class: "min-w-0 flex-1 text-sm leading-6" }, [
                          _createElementVNode("label", {
                            for: "apple-pay",
                            class: "font-medium text-gray-900 cursor-pointer"
                          }, "Apple Pay")
                        ], -1))
                      ]),
                      _cache[30] || (_cache[30] = _createElementVNode("div", { class: "min-w-0 flex-1 text-sm leading-6 rounded-lg" }, [
                        _createElementVNode("img", {
                          width: "200",
                          class: "rounded",
                          src: "https://www.mouqy.com/wp-content/uploads/2023/04/apple-pay-payment.jpg",
                          alt: ""
                        })
                      ], -1))
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(savedCards.value, (savedCard, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "relative pl-4 flex min-w-[50%] md:min-w-[25%] items-start pb-4 pt-3.5 h-full"
                      }, [
                        _createElementVNode("div", _hoisted_38, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((paymentDetails.value.cardOption) = $event)),
                            value: savedCard.id,
                            id: `account-${savedCard.id}`,
                            "aria-describedby": `account-${savedCard.id}-description`,
                            type: "radio",
                            class: "h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                          }, null, 8, _hoisted_39), [
                            [_vModelRadio, paymentDetails.value.cardOption]
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_40, [
                          _createElementVNode("label", {
                            for: `account-${savedCard.id}`,
                            class: "font-medium text-gray-900 cursor-pointer"
                          }, _toDisplayString(savedCard.card.name), 9, _hoisted_41),
                          _createElementVNode("p", {
                            id: `account-${savedCard.id}-description`,
                            class: "text-gray-500"
                          }, _toDisplayString(savedCard.card.brand) + " ••••" + _toDisplayString(savedCard.card.last_four), 9, _hoisted_42),
                          (savedCard.id == paymentDetails.value.cardOption)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                                _withDirectives(_createElementVNode("input", {
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((paymentDetails.value.card.cvc) = $event)),
                                  type: "text",
                                  placeholder: "CVV",
                                  class: _normalizeClass([!errors.value.cardCVC ? 'border-gray-300' : 'border-theme-error', "w-[100px] pl-1 text-xs py-1 rounded-md outline-none border"])
                                }, null, 2), [
                                  [_vModelText, paymentDetails.value.card.cvc]
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true),
            (!contactDetails.value.needConfirmation && paymentDetails.value.cardOption == 'new')
              ? (_openBlock(), _createElementBlock("div", _hoisted_44, _cache[31] || (_cache[31] = [
                  _createElementVNode("div", { class: "bg-theme-gray-7 px-5 py-3.5 text-theme-gray-2 text-size-2 uppercase" }, "Payment details", -1)
                ])))
              : _createCommentVNode("", true),
            (!contactDetails.value.needConfirmation && paymentDetails.value.cardOption == 'new')
              ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                  _createElementVNode("div", _hoisted_46, [
                    _createElementVNode("div", _hoisted_47, [
                      _createElementVNode("div", {
                        class: _normalizeClass([!errors.value.cardName ? 'text-theme-gray-2' : 'text-theme-error', "uppercase text-small flex items-center gap-1"])
                      }, [
                        _cache[32] || (_cache[32] = _createElementVNode("span", null, "Card holder name *", -1)),
                        (errors.value.cardName)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_48, "(required)"))
                          : _createCommentVNode("", true)
                      ], 2),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((paymentDetails.value.card.name) = $event)),
                        type: "text",
                        class: "outline-none w-full h-30 font-bold text-size-6 border-none"
                      }, null, 512), [
                        [_vModelText, paymentDetails.value.card.name]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_49, [
                      _createElementVNode("div", _hoisted_50, [
                        _createElementVNode("div", {
                          class: _normalizeClass([!errors.value.cardNumber ? 'text-theme-gray-2' : 'text-theme-error', "uppercase text-small flex items-center gap-1"])
                        }, [
                          _cache[33] || (_cache[33] = _createElementVNode("span", null, "Card number *", -1)),
                          (errors.value.cardNumber)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_51, "(" + _toDisplayString(errors.value.cardNumber) + ")", 1))
                            : _createCommentVNode("", true)
                        ], 2),
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((paymentDetails.value.card.number) = $event)),
                          type: "number",
                          class: "outline-none w-full h-30 font-bold text-size-6 border-none"
                        }, null, 512), [
                          [_vModelText, paymentDetails.value.card.number]
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_52, [
                    _createElementVNode("div", _hoisted_53, [
                      _createElementVNode("div", {
                        class: _normalizeClass([!errors.value.cardCVC ? 'text-theme-gray-2' : 'text-theme-error', "uppercase text-small flex items-center gap-1"])
                      }, [
                        _cache[34] || (_cache[34] = _createElementVNode("span", null, "CVC *", -1)),
                        (errors.value.cardCVC)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_54, "(required)"))
                          : _createCommentVNode("", true)
                      ], 2),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((paymentDetails.value.card.cvc) = $event)),
                        type: "text",
                        class: "outline-none w-full h-30 font-bold text-size-6 border-none"
                      }, null, 512), [
                        [_vModelText, paymentDetails.value.card.cvc]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_55, [
                      _createElementVNode("div", _hoisted_56, [
                        _createElementVNode("div", {
                          class: _normalizeClass([!errors.value.cardExpiration ? 'text-theme-gray-2' : 'text-theme-error', "uppercase text-small flex items-center gap-1"])
                        }, [
                          _cache[35] || (_cache[35] = _createElementVNode("span", null, "Expiration *", -1)),
                          (errors.value.cardExpiration)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_57, "(required)"))
                            : _createCommentVNode("", true)
                        ], 2),
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((paymentDetails.value.card.expiration) = $event)),
                          type: "month",
                          class: "outline-none w-full h-30 font-bold text-size-6 border-none"
                        }, null, 512), [
                          [_vModelText, paymentDetails.value.card.expiration]
                        ])
                      ])
                    ])
                  ]),
                  (errors.value.responseError)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_58, _toDisplayString(errors.value.responseError), 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (!contactDetails.value.needConfirmation)
              ? (_openBlock(), _createElementBlock("div", _hoisted_59, _cache[36] || (_cache[36] = [
                  _createTextVNode(" Payment Security Note:"),
                  _createElementVNode("br", null, null, -1),
                  _createTextVNode("At VehitraWorld, your payment security is our top priority. All transactions are processed through secure, encrypted connections to protect your personal and financial information. We do not store your credit card details. Book with confidence! ")
                ])))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_60, [
              _createElementVNode("div", {
                onClick: _cache[16] || (_cache[16] = ($event: any) => (_unref(router).push({name: 'BookingWizard', params:{step: 'choose-vehicle'}}))),
                class: "w-full md:w-fit gap-4 uppercase rounded-full md:px-8 py-4 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer transition-all ease-in-out duration-500"
              }, [
                _createElementVNode("span", _hoisted_61, [
                  _createVNode(_component_font_awesome_icon, {
                    size: "lg",
                    icon: "fa-solid fa-chevron-left"
                  })
                ]),
                _cache[37] || (_cache[37] = _createElementVNode("span", null, "Choose car", -1))
              ]),
              _createElementVNode("div", _hoisted_62, [
                _cache[38] || (_cache[38] = _createStaticVNode("<div class=\"flex gap-4 flex-row justify-center md:order-1 order-2 mt-2\" data-v-72cecacc><a href=\"https://wa.me/97332141440\" class=\"text-theme-gray-3 ml-auto flex gap-1 items-center\" target=\"_blank\" data-v-72cecacc><img class=\"h-9 w-9\" src=\"/assets/icons/whatsapp.svg\" alt=\"\" data-v-72cecacc></a><a href=\"tel:+97332141440\" class=\"text-theme-gray-3 ml-auto bg-[#67c15e] rounded-full p-2\" target=\"_blank\" data-v-72cecacc><img class=\"h-6 w-6\" src=\"/assets/icons/tel.svg\" alt=\"\" data-v-72cecacc></a></div>", 1)),
                _createElementVNode("div", {
                  onClick: handleBookingProceed,
                  class: _normalizeClass([isLoading.value ? 'cursor-not-allowed opacity-60' : 'hover:bg-white hover:text-theme-active cursor-pointer', "order-1 md:order-2 border-2 px-8 py-4 rounded-full border-theme-active bg-theme-active text-white text-size-2 flex items-center justify-center w-full md:w-auto md:ml-auto transition-all ease-in-out duration-500"])
                }, [
                  _createElementVNode("span", null, _toDisplayString(contactDetails.value.needConfirmation ? 'Request a Quote' : 'Reserve'), 1),
                  _createElementVNode("span", null, [
                    _createVNode(_component_font_awesome_icon, {
                      size: "lg",
                      icon: "fa-solid fa-chevron-right"
                    })
                  ])
                ], 2)
              ])
            ]),
            _cache[40] || (_cache[40] = _createElementVNode("div", { class: "flex flex-col md:flex-row items-center mt-6" }, null, -1))
          ]))
    ]),
    _createVNode(Notification, {
      show: notif.value.show,
      type: notif.value.type,
      message: notif.value.message,
      messageDetails: notif.value.messageDetails,
      onClosed: notifClosed
    }, null, 8, ["show", "type", "message", "messageDetails"]),
    _createVNode(Loader, { show: isLoading.value }, null, 8, ["show"])
  ], 64))
}
}

})