import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, resolveComponent as _resolveComponent, unref as _unref, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0" }
const _hoisted_2 = { class: "flex flex-col w-full lg:w-theme-auth mx-auto gap-6" }
const _hoisted_3 = { class: "flex flex-col gap-2" }
const _hoisted_4 = { class: "flex flex-row items-center border border-theme-gray-7" }
const _hoisted_5 = { class: "flex flex-col gap-2" }
const _hoisted_6 = { class: "flex flex-row items-center border border-theme-gray-7" }
const _hoisted_7 = ["type"]
const _hoisted_8 = { class: "flex flex-col md:flex-row md:items-center gap-4 mt-2" }

import { ref } from 'vue'
import TopBanner from '@/components/TopBanner.vue'
import { NotificationType } from '@/types/index'
import Auth from '@/composables/Auth'
import { handleApiError, saveAuthCredentials } from '@/utils/common'
import Notification from '@/widgets/Notification.vue'
import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props) {

const passwordFieldtype = ref<'password' | 'text'>('password')
const email = ref<string>('')
const password = ref<string>('')
const isLoading = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const { loginUser }  = Auth()
const router = useRouter()

const togglePasswordFieldType = () :void => {
   passwordFieldtype.value = passwordFieldtype.value == 'password' ? 'text' : 'password'
}

const handleLogin = async () : Promise<void> => {

   if (email.value != '' && password.value != '' && !isLoading.value) {
      
      isLoading.value = true
      const response = await loginUser(email.value, password.value)
      isLoading.value = false
      if (response.status == 200) {
         const accountType = saveAuthCredentials(response.data)

         if (accountType == 'agent' || accountType == 'corporate') {
            router.push({name: 'AccountDashboard'})
         } else {
            //router.push({name: 'Home'})
            location.reload()
         }
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: error, messageDetails: ''}
         setTimeout(() => { notifClosed() }, 4000)
      }
   }
}

const notifClosed = () : void => {
   notif.value.show = false
}


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TopBanner, {
      layout: "full-width",
      currentPage: "Login",
      heading: "Login",
      bannerClass: "banner-auth"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[3] || (_cache[3] = _createElementVNode("label", {
            for: "login-username",
            class: "uppercase text-small text-theme-gray-4"
          }, "Username or email address *", -1)),
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
              type: "text",
              id: "login-username",
              class: "w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6"
            }, null, 512), [
              [_vModelText, email.value]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[4] || (_cache[4] = _createElementVNode("label", {
            for: "login-password",
            class: "uppercase text-small text-theme-gray-4"
          }, "Password *", -1)),
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
              type: passwordFieldtype.value,
              id: "login-password",
              class: "w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6"
            }, null, 8, _hoisted_7), [
              [_vModelDynamic, password.value]
            ]),
            _createElementVNode("div", { onClick: togglePasswordFieldType }, [
              _createVNode(_component_font_awesome_icon, {
                icon: "fa-solid fa-eye",
                class: "text-theme-gray-4 cursor-pointer ml-auto mr-1"
              })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(router).push({ name: 'ForgetPassword' }))),
            class: "order-2 md:order-1 gap-4 uppercase rounded-full px-4 md:px-8 py-2 md:py-4 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer transition-all ease-in-out duration-500"
          }, " Lost your password "),
          _createElementVNode("div", {
            onClick: handleLogin,
            class: _normalizeClass([isLoading.value ? 'cursor-not-allowed opacity-40' : 'cursor-pointer hover:text-theme-active hover:bg-white', "order-1 md:order-2 gap-4 uppercase rounded-full w-full md:w-auto px-4 md:px-8 py-2 md:py-4 border-2 border-theme-active text-white bg-theme-active text-theme-gray-2 text-size-2 flex items-center justify-center ml-auto transition-all ease-in-out duration-500"])
          }, " Login ", 2)
        ])
      ])
    ]),
    _createVNode(Notification, {
      show: notif.value.show,
      type: notif.value.type,
      message: notif.value.message,
      messageDetails: notif.value.messageDetails,
      onClosed: notifClosed
    }, null, 8, ["show", "type", "message", "messageDetails"])
  ], 64))
}
}

})