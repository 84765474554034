<template>
   <DataTable v-if="!isLoading"
      exportFilename="bookings"
      ref="dt"
      class="text-sm"
      v-model:filters="filters" 
      :value="bookingData"
      paginator showGridlines :rows="10" 
      dataKey="id"
      filterDisplay="menu" 
      :loading="isLoading"
      :globalFilterFields="[
         'name', 
         'status', 
         'search.pickUp.date', 
         'search.pickUp.time', 
         'contact.name', 
         'contact.email', 
         'contact.phone',
         'bookedBy.name',
         'createdAt',
      ]"
   >
      <template #header>
         <div class="flex gap-2 w-full justify-end">
            <span class="flex items-center gap-2 p-input-icon-left">
               <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
            </span>
            <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined @click="clearFilter()" />
            <Button icon="pi pi-external-link" label="Export" @click="exportCSV()" />
         </div>
      </template>
      <template #empty> No bookings found. </template>
      <template #loading> Loading bookings data. Please wait. </template>
      <Column field="number" header="Number" exportHeader="Booking Number">
         <template #body="{ data }">
            {{ data.number }}
         </template>
         <template #filter="{ filterModel }">
            <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search booking number" />
         </template>
      </Column>
      <Column header="Pick up" field="search.pickUp" :exportable="false" style="min-width: 8rem">
         <template #body="{ data }">
            <span v-if="data.search && data.search.pickUp">
               {{ data.search.pickUp.date }} <br> {{ data.search.pickUp.time }}
            </span>
         </template>
      </Column>
      <Column field="search.pickUp.location.name" exportHeader="Pick up" class="hidden">
         <template #body="{ data }">
            <span v-if="data.search && data.search.pickUp && data.search.pickUp.location">
               {{ data.search.pickUp.location.name }}
            </span>
         </template>
      </Column>
      <Column header="Booked Car" field="car.name"></Column>
      <Column field="bookedBy.name" exportHeader="Booked By" class="hidden">
         <template #body="{ data }">
            {{ data.bookedBy ? data.bookedBy.name : 'N/A' }}
         </template>
      </Column>
      <Column field="contact.name" exportHeader="Customer Name" class="hidden">
         <template #body="{ data }">
            {{ data.contact.name }}
         </template>
      </Column>
      <Column field="contact.phone" exportHeader="Customer Phone" class="hidden">
         <template #body="{ data }">
            {{ data.contact.phone }}
         </template>
      </Column>
      <Column header="Customer Info" field="contact" :exportable="false">
         <template #body="{ data }">
            <div class="flex flex-col gap-1">
               <div>{{ data.contact.name }}</div>
               <div v-if="data.contact.name">
                  <i class="pi pi-phone" style="font-size: 0.7rem"></i> {{ data.contact.phone }}
               </div>
            </div>
         </template>
      </Column>
      <Column header="Booked By" filterField="bookedBy" :showFilterMatchModes="false" :filterMenuStyle="{ width: '14rem' }" style="min-width: 14rem">
         <template #body="{ data }">
            <div class="flex items-center gap-2">
               <img v-if="data.bookedBy" class="rounded-full w-[32px] h-[32px]" :alt="data.bookedBy ? data.bookedBy.name : ''" :src="data.bookedBy.photo ? data.bookedBy.photo : '/assets/avatars/default.jpg'" />
               <span>{{ data.bookedBy ? data.bookedBy.name : 'N/A' }}</span>
            </div>
         </template>
         <template #filter="{ filterModel }">
            <MultiSelect v-model="filterModel.value" :options="subAgentsData" optionLabel="name" placeholder="Any" class="p-column-filter">
                  <template #option="slotProps">
                     <span class="flex items-center gap-2">
                        <img class="rounded-full w-[32px] h-[32px]" :alt="slotProps.option.name" :src="slotProps.option.photo" />
                        <span>{{ slotProps.option.name }}</span>
                     </span>
                  </template>
            </MultiSelect>
         </template>
      </Column>
      <Column header="Booked At" exportHeader="Booked At" style="min-width: 8rem">
         <template #body="{ data }">
            <div class="flex items-center gap-2">
               <span>{{ data.createdAt ? formattedDateTime(data.createdAt) : 'N/A' }}</span>
            </div>
         </template>
      </Column>
      <Column field="search.pickUp.date" exportHeader="Pick up date" class="hidden">
         <template #body="{ data }">
            <span v-if="data.search && data.search.pickUp">
               {{ data.search.pickUp.date }}
            </span>
         </template>
      </Column>
      <Column field="search.pickUp.time" exportHeader="Pick up time" class="hidden">
         <template #body="{ data }">
            <span v-if="data.search && data.search.pickUp">
               {{ data.search.pickUp.time }}
            </span>
         </template>
      </Column>
      <Column field="totalAmount" header="Booking Total" style="min-width: 10rem">
         <template #body="{ data }">
            {{ data.totalAmount ? data.totalAmount : 'N/A' }}
         </template>
      </Column>
      <Column field="commission.amount" header="Commission" style="min-width: 8rem">
         <template #body="{ data }">
            {{ data.commission && data.commission.amount ? `${data.commission.amount.toFixed(2)} (${data.commission.percent}%)`  : 'N/A' }}
         </template>
      </Column>
      <Column header="Status" field="status">
         <template #body="{ data }">
            <div :class="getStatusClass(data.status)" class="rounded rounded-bg text-center min-w-[85px] py-2 uppercase text-xs">
               {{ data.status }}
            </div>
         </template>
         <template #filter="{ filterModel }">
            <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Select One" class="p-column-filter" showClear>
                  <template #option="slotProps">
                     {{ slotProps.option }}
                  </template>
            </Dropdown>
         </template>
      </Column>
      <Column header="Actions" :exportable="false" class="w-[12rem]">
         <template #body="{ data }">
            <div v-if="data.status == 'confirmed' || data.status == 'cancelled'" class="flex flex-col gap-2">
               <div @click="router.push({name: 'UserBookingVoucher', params: {number: data.number}})" class="bg-theme-button1-bg text-theme-button1-txt text-center rounded w-[80px] py-1 cursor-pointer">
                  Voucher
               </div>
               <div @click="router.push({name: 'UserBookingInvoice', params: {number: data.number}})" class="bg-theme-button3-bg text-theme-button3-txt text-center rounded w-[80px] py-1 cursor-pointer">
                  Invoice
               </div>
            </div>
            <div v-else>
               <div @click="router.push({name: 'UserBookingDetails', params: {number: data.number}})" class="bg-theme-button2-bg text-theme-button2-txt text-center rounded w-[80px] py-1 cursor-pointer">
                  Details
               </div>
            </div>
            <span v-if="data.status != 'confirmed' && data.status != 'need_confirmation' && data.status != 'unconfirmed' && data.search && data.search.pickUp && isMakePaymentVisible(data.search.pickUp.date)" @click="makeBookingPayment(data)" class="text-indigo-500 hover:text-indigo-600 cursor-pointer">
               | Make Payment
            </span> 
         </template>
      </Column>
   </DataTable>
   <div v-else class="text-center font-bold h-[50px] mt-12 w-full">
      Loading data please wait...
   </div>
   <!--
      <SlideOver :show="openDialog" @closed="openDialog = false">
         <template v-slot:content>
            <BookingDetails :data="selectedBooking" />
         </template>
      </SlideOver>
   -->
</template>

<script setup lang="ts">
import { ref, onMounted, watch, toRef } from 'vue'
import Agent from '@/composables/admin/agent'
import Corporate from '@/composables/admin/corporate'
import { handleApiError, isLoggedIn, isAdminLoggedIn, saveBookingNumber, isMakePaymentVisible, formattedDateTime, logout } from '@/utils/common'
import SlideOver from '@/widgets/SlideOver.vue'
import BookingDetails from '@/components/admin/BookingDetails.vue'
import { STATUSES } from '@/utils/constants'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import { saveUserSearch } from '@/services/search-service'
import CarService from '@/services/car-service'
import Admin from '@/composables/admin'
import router from '@/router'

const { saveSelectedCar } = CarService()
const bookingData = ref<any>(null)
const subAgentsData = ref([])
const openDialog = ref<boolean>(false)
const selectedBooking = ref<any>(null)

const props = defineProps(['subAgents', 'bookings', 'isLoading', 'exportAs'])

onMounted(async () => {
   bookingData.value = props.bookings
   subAgentsData.value = props.subAgents
})

watch(() => props.isLoading, (newValue, oldValue) => {
   bookingData.value = props.bookings
   subAgentsData.value = props.subAgents
})

const handleBookingDetails = (booking: any) => {

   selectedBooking.value = booking
   openDialog.value = true
}

const getStatusClass = (status: string) => {

   if (status == 'incomplete') {
      return 'bg-theme-button4-bg text-theme-button4-txt'
   } else if (status == 'confirmed') {
      return 'bg-theme-button1-bg text-theme-button1-txt'
   } else if (status == 'awaiting_payment') {
      return 'bg-theme-button2-bg text-theme-button2-txt'
   } else if (status == 'need_confirmation') {
      return 'bg-theme-button3-bg text-theme-button3-txt'
   } else if (status == 'cancelled') {
      return 'bg-theme-button5-bg text-theme-button5-txt'
   } else if (status == 'unconfirmed') {
      return 'bg-theme-button3-bg text-theme-button3-txt'
   } else {
      return 'bg-theme-button-bg text-theme-button-txt'
   }
}

const filters = ref()
const statuses = STATUSES.map(status => status.title)
const dt = ref()

const initFilters = () => {
   filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      number: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      bookedBy: { value: null, matchMode: FilterMatchMode.IN },
   }
}

initFilters()

const clearFilter = () => {
   initFilters()
}

const exportCSV = () => {

   const allData = dt.value.filteredValue || dt.value.value || []
   const bookingCount = allData.length
   const salesTotal = allData.reduce((sum: number, record: any) => sum + (parseFloat(record.totalAmount) || 0), 0)

   const headers = [
      'Booking Number',
      'Customer Name',
      'Customer Phone',
      'Booked Car',
      'Booked By',
      'Booked At',
      'Pick up',
      'Pick up Date',
      'Pick up Time',
      'Status',
      'Booking Total',
      'Commission',
   ].join(',')

   const rows = allData.map((record : any) =>
      [
         record.number.replace(/,/g, ' ') || '',
         record.contact?.name?.replace(/,/g, ' ') || '',
         record.contact?.phone?.replace(/,/g, ' ') || '',
         record.car?.name?.replace(/,/g, ' ') || '',
         record.bookedBy?.name?.replace(/,/g, ' ') || 'N/A',
         record.createdAt ? formattedDateTime(record.createdAt).replace(/,/g, ' ') : 'N/A',
         record.search?.pickUp?.location?.name?.replace(/,/g, ' ') || '',
         record.search?.pickUp?.date?.replace(/,/g, ' ') || '',
         record.search?.pickUp?.time?.replace(/,/g, ' ') || '',
         record.status?.replace(/,/g, ' ') || '',
         record.totalAmount?.replace(/,/g, ' ') || 'N/A',
         `${record.commission?.amount?.toFixed(2)} (${record.commission?.percent}%)` || 'N/A',
      ].join(',')
   )

   rows.push("")
   rows.push(`Total Bookings, ${bookingCount}`)
   rows.push(`Total Sales, ${salesTotal.toFixed(2)}`)

   const csvContent = [headers, ...rows].join('\n')
   const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
   const link = document.createElement('a')
   link.href = URL.createObjectURL(blob)
   link.download = props.exportAs && props.exportAs != '' ? `${props.exportAs}.csv` : 'report.csv'
   link.click()
}

const makeBookingPayment = (booking: any) => {

   saveUserSearch(booking.search)
   saveBookingNumber(booking.number)
   saveSelectedCar(booking.car)
   router.push({name: 'BookingWizard', params: {step: 'contact-details'}})
}

</script>