<template>
   <div id="3DSUI"></div>
   <div class="grid grid-cols-12 md:gap-10"> 
      <div v-if="!hideElements" class="col-span-12 md:col-span-3 flex flex-col h-fit order-2 md:order-1 mt-12 md:mt-0">
         <BookingSummary :currentStep="currentStep" />
      </div>
      <div v-if="html.formAction && html.fieldValue != ''" class="challenge-form mx-auto col-span-12 md:col-span-12 flex flex-col justify-center order-1 md:order-2 py-12">
         <form id="threedsChallengeRedirectForm" style="width:100vh;" target="challengeFrame" :action="html.formAction" method="post">
            <input v-model="html.fieldValue" type="hidden" name="creq">
            <iframe id="challengeFrame" name="challengeFrame" style="width:100%; min-height:500px;" frameborder="0"></iframe>
         </form>
      </div>
      <div v-else class="col-span-12 md:col-span-9 flex flex-col order-1 md:order-2">
         <div class="flex flex-col">
            <div class="bg-theme-gray-7 px-5 py-3.5 text-theme-gray-2 text-size-2 uppercase">Guest Contact details</div>
         </div>
         <div class="flex flex-col">
            <div class="grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7">
               <div class="col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5">
                  <div :class="!errors.firstName ? 'text-theme-gray-2' : 'text-theme-error'" class="uppercase text-small flex items-center gap-1">
                     <span>First name *</span>
                     <span v-if="errors.firstName" class="text-theme-error">(required)</span>
                  </div>
                  <input v-model="contactDetails.firstName" type="text" class="outline-none w-full h-30 font-bold text-size-6 border-none ">
               </div>
               <div class="col-span-2 md:col-span-1">
                  <div class="col-span-1 flex flex-col gap-2 items-start px-4 py-5">
                     <div :class="!errors.lastName ? 'text-theme-gray-2' : 'text-theme-error'" class="uppercase text-small flex items-center gap-1">
                        <span>Last name *</span>
                        <span v-if="errors.lastName" class="text-theme-error">(required)</span>
                     </div>
                     <input v-model="contactDetails.lastName" type="text" class="outline-none w-full h-30 font-bold text-size-6 border-none ">
                  </div>
               </div>
            </div>
            <div class="grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7">
               <div class="col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5">
                  <div :class="!errors.email ? 'text-theme-gray-2' : 'text-theme-error'" class="uppercase text-small flex items-center gap-1">
                     <span>Email *</span>
                     <span v-if="errors.email" class="text-theme-error">({{ errors.email }})</span>
                  </div>
                  <input v-model="contactDetails.email" type="text" class="outline-none w-full h-30 font-bold text-size-6 border-none ">
               </div>
               <div class="col-span-2 md:col-span-1">
                  <div class="col-span-1 flex flex-col gap-2 items-start px-4 py-5 relative">
                     <div :class="!errors.phoneNumber ? 'text-theme-gray-2' : 'text-theme-error'" class="uppercase text-small flex items-center gap-1">
                        <span>Phone number *</span>
                        <span v-if="errors.phoneNumber" class="text-theme-error">({{ errors.phoneNumber }})</span>
                     </div>
                     <CountrySearchablePhoneField @phoneNumber="handlePhoneChange" />
                  </div>
               </div>
            </div>
            <div class="grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7">
               <div class="col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5">
                  <div class="uppercase text-small text-theme-gray-2 flex items-center gap-1">
                     <span>Flight Number (optional)</span>
                  </div>
                  <input v-model="contactDetails.flightNumber" type="text" class="outline-none w-full h-30 font-bold text-size-6 border-none ">
               </div>
               <div class="col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5">
                  <div class="uppercase text-theme-gray-2 text-small">Comments</div>
                  <textarea v-model="contactDetails.comments" class="outline-none w-full h-30 font-bold text-size-6 border-none h-[60px]"></textarea>
               </div>
            </div>
         </div>
         <div v-if="accountType == 'agent'">
            <div class="flex flex-col mt-6">
               <div class="bg-theme-gray-7 px-5 py-3.5 text-theme-gray-2 text-size-2 uppercase">Booking reference number</div>
            </div>
            <div class="flex flex-col">
               <div class="grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7">
                  <div class="col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5">
                     <div class="uppercase text-small text-theme-gray-2 flex items-center gap-1">
                        <span>Enter Your Booking Reference Number (optional)</span>
                     </div>
                     <input v-model="bookingRefNo" type="text" class="outline-none w-full h-30 font-bold text-size-6 border-none ">
                  </div>
               </div>
            </div>
         </div>
         <div v-if="!contactDetails.needConfirmation" class="flex flex-col mt-6">
            <div class="bg-theme-gray-7 px-5 py-3.5 text-theme-gray-2 text-size-2 uppercase">Choose Payment Option</div>
         </div>
         <div v-if="!contactDetails.needConfirmation" class="flex flex-col">
            <div class="divide-x divide-gray-200 flex items-start gap-4 overflow-x-auto border border-gray-200 saved-cards">
               <div class="relative pl-4 flex min-w-[50%] md:min-w-[25%] items-start pb-4 pt-3.5">
                  <div class="mr-3 flex h-6 items-center">
                     <input v-model="paymentDetails.cardOption" value="new" id="account-new" aria-describedby="account-new-description" type="radio" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer" />
                  </div>
                  <div class="min-w-0 flex-1 text-sm leading-6">
                     <label for="account-new" class="font-medium text-gray-900 cursor-pointer">New Card</label>
                  </div>
               </div>
               <div v-if="credit && canPayViaCredit(credit as CreditType)" class="relative pl-4 h-full flex min-w-[50%] md:min-w-[25%] items-start pb-4 pt-3.5">
                  <div class="mr-3 flex h-6 items-center">
                     <input v-model="paymentDetails.cardOption" value="credit" id="use-credit" aria-describedby="account-new-credit" type="radio" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer" />
                  </div>
                  <div class="min-w-0 flex-1 text-sm leading-6">
                     <label for="use-credit" class="font-medium text-gray-900 cursor-pointer">Use Credit</label>
                     <p class="text-gray-500">Credit Balance: {{ credit!.amount - credit!.used }}</p>
                  </div>
               </div>
               <div class="hidden apple_pay_button relative pl-4 flex flex-col gap-2 min-w-[50%] md:min-w-[25%] items-start pb-4 pt-3.5 h-full">
                  <div class="flex">
                     <div class="mr-3 flex h-6 items-center">
                        <input v-model="paymentDetails.cardOption" id="apple-pay" value="apple_pay" type="radio" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer" />
                     </div>
                     <div class="min-w-0 flex-1 text-sm leading-6">
                        <label for="apple-pay" class="font-medium text-gray-900 cursor-pointer">Apple Pay</label>
                     </div>
                  </div>
                  <div class="min-w-0 flex-1 text-sm leading-6 rounded-lg">
                     <img width="200" class="rounded" src="https://www.mouqy.com/wp-content/uploads/2023/04/apple-pay-payment.jpg" alt="">
                  </div>
               </div>
               <div v-for="(savedCard, index) in savedCards" :key="index" class="relative pl-4 flex min-w-[50%] md:min-w-[25%] items-start pb-4 pt-3.5 h-full">
                  <div class="mr-3 flex h-6 items-center">
                     <input v-model="paymentDetails.cardOption" :value="savedCard.id" :id="`account-${savedCard.id}`" :aria-describedby="`account-${savedCard.id}-description`" type="radio" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer" />
                  </div>
                  <div class="min-w-0 flex-1 text-sm leading-6">
                     <label :for="`account-${savedCard.id}`" class="font-medium text-gray-900 cursor-pointer">{{ savedCard.card.name }}</label>
                     <p :id="`account-${savedCard.id}-description`" class="text-gray-500">{{ savedCard.card.brand }} ••••{{ savedCard.card.last_four }}</p>
                     <div v-if="savedCard.id == paymentDetails.cardOption" class="mt-1 flex h-6 items-center">
                        <input v-model="paymentDetails.card.cvc" type="text" placeholder="CVV" :class="!errors.cardCVC ? 'border-gray-300' : 'border-theme-error'" class="w-[100px] pl-1 text-xs py-1 rounded-md outline-none border" />
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div v-if="!contactDetails.needConfirmation && paymentDetails.cardOption == 'new'" class="flex flex-col mt-6">
            <div class="bg-theme-gray-7 px-5 py-3.5 text-theme-gray-2 text-size-2 uppercase">Payment details</div>
         </div>
         <div v-if="!contactDetails.needConfirmation && paymentDetails.cardOption == 'new'" class="flex flex-col">
            <div class="grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7">
               <div class="col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5">
                  <div :class="!errors.cardName ? 'text-theme-gray-2' : 'text-theme-error'" class="uppercase text-small flex items-center gap-1">
                     <span>Card holder name *</span>
                     <span v-if="errors.cardName" class="text-theme-error">(required)</span>
                  </div>
                  <input v-model="paymentDetails.card.name" type="text" class="outline-none w-full h-30 font-bold text-size-6 border-none ">
               </div>
               <div class="col-span-2 md:col-span-1">
                  <div class="col-span-1 flex flex-col gap-2 items-start px-4 py-5">
                     <div :class="!errors.cardNumber ? 'text-theme-gray-2' : 'text-theme-error'" class="uppercase text-small flex items-center gap-1">
                        <span>Card number *</span>
                        <span v-if="errors.cardNumber" class="text-theme-error">({{ errors.cardNumber }})</span>
                     </div>
                     <input v-model="paymentDetails.card.number" type="number" class="outline-none w-full h-30 font-bold text-size-6 border-none ">
                  </div>
               </div>
            </div>
            <div class="grid grid-cols-2 md:divide-x divide-y md:divide-y-0 divide-text-gray-7 border-r border-l border-b border-theme-gray-7">
               <div class="col-span-2 md:col-span-1 flex flex-col gap-2 items-start px-4 py-5">
                  <div :class="!errors.cardCVC ? 'text-theme-gray-2' : 'text-theme-error'" class="uppercase text-small flex items-center gap-1">
                     <span>CVC *</span>
                     <span v-if="errors.cardCVC" class="text-theme-error">(required)</span>
                  </div>
                  <input v-model="paymentDetails.card.cvc" type="text" class="outline-none w-full h-30 font-bold text-size-6 border-none ">
               </div>
               <div class="col-span-2 md:col-span-1">
                  <div class="col-span-1 flex flex-col gap-2 items-start px-4 py-5">
                     <div :class="!errors.cardExpiration ? 'text-theme-gray-2' : 'text-theme-error'" class="uppercase text-small flex items-center gap-1">
                        <span>Expiration *</span>
                        <span v-if="errors.cardExpiration" class="text-theme-error">(required)</span>
                     </div>
                     <input v-model="paymentDetails.card.expiration" type="month" class="outline-none w-full h-30 font-bold text-size-6 border-none ">
                  </div>
               </div>
            </div>
            <div v-if="errors.responseError" class="text-theme-error mt-4">
               {{ errors.responseError }}
            </div>
         </div>
         <div v-if="!contactDetails.needConfirmation" class="flex flex-col mt-4 text-theme-gray-3 text-sm">
            Payment Security Note:<br>At VehitraWorld, your payment security is our top priority. All transactions are processed through secure, encrypted connections to 
            protect your personal and financial information. We do not store your credit card details. Book with confidence!
         </div>
         <div class="flex flex-col md:flex-row items-center mt-6">
            <div @click="router.push({name: 'BookingWizard', params:{step: 'choose-vehicle'}})" class="w-full md:w-fit gap-4 uppercase rounded-full md:px-8 py-4 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer transition-all ease-in-out duration-500">
               <span class="text-theme-gray-2">
                  <font-awesome-icon size="lg" icon="fa-solid fa-chevron-left" />
               </span>
               <span>Choose car</span>
            </div>
            <div class="gap-4 mt-12 md:ml-auto flex flex-col md:flex-row md:mt-auto uppercase w-full md:w-auto items-center">
               <div class="flex gap-4 flex-row justify-center md:order-1 order-2 mt-2">
                  
                  <a :href="'https://wa.me/97332141440'" class="text-theme-gray-3 ml-auto flex gap-1 items-center" target="_blank">
                     <img class="h-9 w-9" src="/assets/icons/whatsapp.svg" alt="">
                  </a>
                  <a :href="'tel:+97332141440'" class="text-theme-gray-3 ml-auto bg-[#67c15e] rounded-full p-2" target="_blank">
                     <img class="h-6 w-6" src="/assets/icons/tel.svg" alt="">
                  </a>
               </div>

               <div @click="handleBookingProceed" :class="isLoading ? 'cursor-not-allowed opacity-60' : 'hover:bg-white hover:text-theme-active cursor-pointer'" class="order-1 md:order-2 border-2 px-8 py-4 rounded-full border-theme-active bg-theme-active text-white text-size-2 flex items-center justify-center w-full md:w-auto md:ml-auto transition-all ease-in-out duration-500">
                  <span>
                     {{ contactDetails.needConfirmation ? 'Request a Quote' : 'Reserve' }}
                  </span>
                  <span>
                     <font-awesome-icon size="lg" icon="fa-solid fa-chevron-right" />
                  </span>
               </div>
            </div>
         </div>
         <div class="flex flex-col md:flex-row items-center mt-6">
            
         </div>
      </div>
   </div>
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
   <Loader :show="isLoading" />
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick } from 'vue'
import BookingSummary from '@/components/BookingSummary.vue'
import CountrySearchablePhoneField from '@/components/CountrySearchablePhoneField.vue'
import { getBackendBaseUrl, getBookingNumber, isValidCardNumber, isValidEmail, resetErrors, savePaymentSession, savePaymentTransaction, savePaymentCode, isLoggedIn, loadScript, handleApiError, savePaymentWallet, canPayViaCredit, getAuthUserAccountType } from '@/utils/common'
import CarService from '@/services/car-service'
import { Car as CarData, SavedCardType, UserSearch, CreditType } from '@/types'
import { retrieveUserSearch } from '@/services/search-service'
import BookingService from '@/services/booking-service'
import Payment from '@/composables/Payment'
import { LOADER_MINIMUM_TIME, credimax, APPLE_PAY_MERCHANT } from '@/utils/constants'
import { useRouter } from 'vue-router'
import creditCardType from 'credit-card-type'
import Car from '@/composables/Car'
import { NotificationType } from '@/types'
import Notification from '@/widgets/Notification.vue'
import Loader from '@/widgets/Loader.vue'
import SavedCard from '@/composables/SavedCard'
import BookingCreditService from '@/services/booking-credit-service'
import ApplePay from '@/composables/ApplePay'
// @ts-ignore
import $ from 'jquery'

declare global {
  interface Window {
    ThreeDS: any;
    ApplePaySession: any;
  }
}

const props = defineProps(['currentStep'])
const { retrieveUserSelectedCar } = CarService()
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const { createSession, updateSession, createToken, initiateAuthentication, authenticatePayer  } = Payment()
const { savedCardList  } = SavedCard()
const { updateBooking } = BookingService()
const { getCredit } = BookingCreditService()
const logs = ref<any>([])
const outputToken = ref<string>('')
const router = useRouter()
const authUser = isLoggedIn()
const contactDetails = ref<any>({
   firstName: '',
   phoneNumber: '',
   flightNumber: '',
   lastName: '',
   email: '',
   comments: '',
   needConfirmation: false
})
const bookingRefNo = ref<string | null>(null)
const paymentDetails = ref<any>({
   card: {
      name: '',
      number: '',
      cvc: '',
      expiration: ''
   },
   token: '',
   session: null,
   order: null,
   transaction: null,
   cardOption: ''
})
const errors = ref<any>({
   firstName: null,
   lastName: null,
   email: null,
   phoneNumber: null,
   cardName: null,
   cardNumber: null,
   cardCVC: null,
   cardExpiration: null,
   responseError: null
})
const accountType = getAuthUserAccountType()
const isLoading = ref<boolean>(false)
const initiateResponsee = ref<any>(null)
const authenticatePayerResponsee = ref<any>(null)
const car : CarData = retrieveUserSelectedCar()
const search : UserSearch = retrieveUserSearch()
const html = ref<any>({
   formAction: null,
   fieldValue: ''
})
const timeElapsed = ref<number>(0)
const savedCards = ref<SavedCardType[] | null>(null)
const credit = ref<CreditType | null>(null)
const hideElements = ref<boolean>(false)
const { validateMerchant, authorizePayment } = ApplePay()

const handleBookingProceed = async () => {
   
   hideElements.value = false
   resetErrors(errors.value)
   let isValid = validateForm()

   if (!isLoading.value && isValid) {

      if (!car || !search) {
         console.log('Error in data collection: ', car, search)
      }

      const bookingNumber = getBookingNumber() as string
      isLoading.value = true
      let tokenResponse

      if (contactDetails.value.needConfirmation == false) {

         paymentDetails.value.order = bookingNumber
         if (paymentDetails.value.cardOption == 'new') {
            paymentDetails.value.card.brand = getCardBrand(paymentDetails.value.card.number.toString())
            tokenResponse = await createToken(paymentDetails.value)
         } else if(paymentDetails.value.cardOption == 'apple_pay') {
            tokenResponse = { status: 'apple_pay' }
         } else if(paymentDetails.value.cardOption == 'credit') {
            tokenResponse = { status: 'credit' }
         } else {
            paymentDetails.value.card.brand = ''
            tokenResponse = { status: 201, data: { token : getSavedCardToken(paymentDetails.value.cardOption) } }
         }
         
         if (tokenResponse.status == 201) {
            const sessionResponse = await updateSession(paymentDetails.value.session, tokenResponse.data.token, bookingNumber as string)
            paymentDetails.value.token = tokenResponse.data.token
            
            if (sessionResponse.status == 200) {
               paymentDetails.value.transaction = sessionResponse.data.transaction
               savePaymentTransaction(paymentDetails.value.transaction)
               
               const initiateResponse = await initiateAuthentication(paymentDetails.value.session, bookingNumber, paymentDetails.value.transaction)
               initiateResponsee.value = JSON.stringify(initiateResponse.data.response)
               
               if (initiateResponse.data.response.transaction.authenticationStatus == 'AUTHENTICATION_AVAILABLE'){
                  const authenticatePayerResponse = await authenticatePayer(paymentDetails.value.session, bookingNumber, `${paymentDetails.value.transaction}`)
                  authenticatePayerResponsee.value = JSON.stringify(authenticatePayerResponse.data.response)
                  const response = authenticatePayerResponse.data.response
                  if (response && response.response && response.response.gatewayRecommendation == 'PROCEED') {
                     const action = response.authentication.redirect.customizedHtml['3ds2'].acsUrl
                     const inputValue = response.authentication.redirect.customizedHtml['3ds2'].cReq
                     html.value = {
                        formAction: action,
                        fieldValue: inputValue
                     }
                     
                     await nextTick()
                     const form = document.getElementById('threedsChallengeRedirectForm') as HTMLFormElement | null
                     if (form) {
                        form.submit()
                        setTimeout(() => isLoading.value = false, 2000)
                        hideElements.value = true
                        $('.booking-steps, .main-header, .main-footer').hide()
                     }
                  } else {
                     errors.value.responseError = 'payer authentication request finished but returned a different than expected response'
                     isLoading.value = false
                  }
               } else {
                  errors.value.responseError = 'initiate authentication request finished but returned a different than expected response'
                  isLoading.value = false
               }
            } else {
               errors.value.responseError = JSON.stringify(sessionResponse)
               isLoading.value = false
            }
         } else if (tokenResponse.status == 'apple_pay') {
            initiateApplePay()
            isLoading.value = false
         } else if (tokenResponse.status == 'credit') {
            await initiateCreditPayment()
         } else {
            errors.value.responseError = JSON.stringify(tokenResponse)
            isLoading.value = false
         }
      } else {
         
         const startTime = performance.now()
         const response = await updateBooking(bookingNumber, contactDetails.value, bookingRefNo.value, paymentDetails.value, true)
         timeElapsed.value = performance.now() - startTime

         if (timeElapsed.value < LOADER_MINIMUM_TIME) {
            const diff = LOADER_MINIMUM_TIME - timeElapsed.value
            setTimeout(() => {
               if (response.success) {
                  router.push({ name: 'BookingWizard', params: { step: 'finish' } })
               }
            }, diff)
         } else {
            if (response.success) {
               router.push({ name: 'BookingWizard', params: { step: 'finish' } })
            }
         }
      }
   }
}

const validateForm = () => {
   
   let response = true
   
   if (contactDetails.value.firstName === '') {
      errors.value.firstName = true
      response = false
   }
   
   if (contactDetails.value.lastName === '') {
      errors.value.lastName = true
      response = false
   }

   if (contactDetails.value.phoneNumber === '') {
      errors.value.phoneNumber = 'Required'
      response = false
   }else if (contactDetails.value.phoneNumber.length < 11) {
      errors.value.phoneNumber = 'Invalid'
      response = false
   }

   if (contactDetails.value.email === '') {
      errors.value.email = 'Required'
      response = false
   } else if (!isValidEmail(contactDetails.value.email)) {
      errors.value.email = 'Invalid'
      response = false
   }
   
   if (contactDetails.value.needConfirmation === false && paymentDetails.value.cardOption == 'new') {
      
      if (paymentDetails.value.card.name === '') {
         errors.value.cardName = true
         response = false
      }
   
      if (paymentDetails.value.card.number === '') {
         errors.value.cardNumber = 'Required'
         response = false
      } else if (!isValidCardNumber(paymentDetails.value.card.number)) {
         errors.value.cardNumber = 'Invalid'
         response = false
      }
   
      if (paymentDetails.value.card.expiration === '') {
         errors.value.cardExpiration = true
         response = false
      }
   } else if (contactDetails.value.needConfirmation === false && paymentDetails.value.cardOption !== 'apple_pay' && paymentDetails.value.cardOption !== 'credit') {

      if (paymentDetails.value.card.cvc == '') {
         errors.value.cardCVC = true
         response = false
      }
   }

   return response
}

const handlePhoneChange = (value: string) => {
   contactDetails.value.phoneNumber = value
}

const getCardBrand = (cardNumber: string) => {
   const cardType = creditCardType(cardNumber);

   if (cardType.length > 0) {
      return cardType[0].niceType
   }

   return 'Unknown'
}

const getSavedCardToken = (cardId: number) : string | null => {

   const card = savedCards.value?.find((card: SavedCardType) => card.id === cardId)
   return card ? card.token : null
}

const checkAndCreateSession = async () : Promise<{status: boolean, session: string | null}> => {

   const sessionResponse = await createSession()
   if (sessionResponse.status == 200) {
      savePaymentSession(sessionResponse.data.session)
      return {status: true, session: sessionResponse.data.session}
   } else {
      return {status: false, session: null}
   }
}

onMounted(async () => {

   const response: any = retrieveUserSelectedCar()
   if (response && response.price === null) {
      contactDetails.value.needConfirmation = true
   }

   const savedCardsResponse = await savedCardList()
   if (savedCardsResponse && savedCardsResponse.status == 200 && savedCardsResponse.data.cards.length) {
      savedCards.value = savedCardsResponse.data.cards
      paymentDetails.value.cardOption = savedCardsResponse.data.cards[savedCardsResponse.data.cards.length-1].id
   } else {
      paymentDetails.value.cardOption = 'new'
   }

   const creditResponse = await getCredit(getBookingNumber())
   if (creditResponse && creditResponse.success) {
      credit.value = creditResponse.data.credit
   }

   showApplePayButton()

   const sessionResponse = await checkAndCreateSession()
   if (!sessionResponse.status) {
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: 'Payment cannot be processed at the moment'}
   } else {
      paymentDetails.value.session = sessionResponse.session
   }
})

const showApplePayButton = () => {
   
   loadScript('https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js', function () {
      if ((window as any).ApplePaySession) {
         var promise = (window as any).ApplePaySession.canMakePaymentsWithActiveCard(APPLE_PAY_MERCHANT)
         promise.then(function (canMakePayments: any) {
            
            if (canMakePayments) {
               $('.apple_pay_button').removeClass('hidden')
            } else {
               alert('You dont have an active card connected to apple pay')
            }
         })
      }
   })
}

const notifClosed = () : void => {
   notif.value.show = false
}

window.addEventListener('message', function(event) {

   const backendUrl = getBackendBaseUrl()
   if (backendUrl.includes(event.origin) && event.data === 'redirect') {
      
      (async () => {
         const bookingNumber = getBookingNumber() as string
         savePaymentCode(paymentDetails.value.card.cvc)
         savePaymentWallet('card')
         const payDetails = {
            card: {
               name: paymentDetails.value.card.name,
               number: paymentDetails.value.card.number.toString().slice(-4),
               brand: paymentDetails.value.card.brand
            },
            token: paymentDetails.value.token
         }
         const response = await updateBooking(bookingNumber, contactDetails.value, bookingRefNo.value, payDetails, false)
         if (response.success) {
            router.push({ name: 'BookingWizard', params: { step: 'finish' } })
         } else {
            console.log(response.error)
         }
      })()
   } else if (backendUrl.includes(event.origin) && event.data === 'error') {

      location.reload()
   }
})

const initiateApplePay = () => {
   
   const total = car.price.toFixed(2)
   if ((window as any).ApplePaySession) {
      
      const paymentRequest = {
         countryCode: 'BH',
         currencyCode: 'USD',
         total: {
            label: 'Vehitra World Payment',
            type: "final",
            amount: total,
         },
         supportedNetworks: [
            "visa",
            "mada",
            "masterCard",
            "amex"
         ],
         merchantCapabilities: [
            "supports3DS"
         ]
      }

      try {
         const session = new (window as any).ApplePaySession(12, paymentRequest)
         session.begin()

         session.onvalidatemerchant = async (event: any) => {

            const validateResponse = await validateMerchant(event.validationURL)

            if (validateResponse.status == 200) {

               console.log(JSON.parse(validateResponse.data))
               session.completeMerchantValidation(JSON.parse(validateResponse.data))
               console.log('merchant validation is successful')
            } else {

               const error = handleApiError(validateResponse)
               console.log(error)
            }
         }

         session.onpaymentmethodselected = (event: any) => {
            const update = {
               "newTotal": {
                  "label": "Click to pay Vehitra Tech",
                  "type": "final",
                  "amount": total
               }
            }

            session.completePaymentMethodSelection(update)
            console.log('on paymentmethod selected complete')
         }

         session.onshippingmethodselected = (event: any) => {

            const update = {}
            session.completeShippingMethodSelection(update)
         }

         session.onshippingcontactselected = (event: any) => {

            const update = {}
            session.completeShippingContactSelection(update)
         }

         session.onpaymentauthorized = async (event: any) => {

            let paymentToken = event.payment.token
            const bookingNumber = getBookingNumber() as string
            const response = await authorizePayment(JSON.stringify(paymentToken), bookingNumber, contactDetails.value)
            const result = {
               'status': response.data.success ? (window as any).ApplePaySession.STATUS_SUCCESS : (window as any).ApplePaySession.STATUS_FAILURE
            }
            session.completePayment(result)

            if (response.data.success) {
               
               savePaymentWallet('apple_pay')
               setTimeout(() => {
                  router.push({ name: 'BookingWizard', params: { step: 'finish' } })
               }, 500)
            }
         }

         session.oncancel = (event: any) => {
            console.log('payment cancelled')
         }

      } catch (error) {
         alert(error)
      }
      
   } else {
      console.error('Your device does not support apple pay')
   }
}

const initiateCreditPayment = async () => {
   
   const canPay = canPayViaCredit(credit.value as CreditType)
   if (canPay) {
      
      const bookingNumber = getBookingNumber() as string
      savePaymentWallet('credit')
      
      const startTime = performance.now()
      const response = await updateBooking(bookingNumber, contactDetails.value, bookingRefNo.value, paymentDetails.value, true)
      timeElapsed.value = performance.now() - startTime

      if (timeElapsed.value < LOADER_MINIMUM_TIME) {
         const diff = LOADER_MINIMUM_TIME - timeElapsed.value
         setTimeout(() => {
            if (response.success) {
               router.push({ name: 'BookingWizard', params: { step: 'finish' } })
            }
         }, diff)
      } else {
         if (response.success) {
            router.push({ name: 'BookingWizard', params: { step: 'finish' } })
         }
      }
   } else {
      errors.value.responseError = 'You cannot pay via credit'
      isLoading.value = false
   }
}

</script>

<style scoped>

.saved-cards::-webkit-scrollbar { height: 10px; }
.saved-cards::-webkit-scrollbar-thumb { background-color: #EAECEE; border-radius: 4px; }
.saved-cards::-webkit-scrollbar-track { background-color: #ffffff; }

</style>