<template>
   <div class="grid grid-cols-1 gap-4 divide-y divide-gray-300 w-full bg-gray-100 p-4 my-12">
      <div class="col-span-1 flex flex-row items-center">
         <img class="w-[235px]" src="/assets/logo.png" alt="">
         <div class="ml-auto text-size-6">Booking Voucher</div>
      </div>
      <div v-if="data.refNumber" class="text-size-6">
         <div class="grid grid-cols-2 gap-4 pt-4">
            <div class="flex flex-col gap-4 col-span-2 md:col-span-1">
               <div class="grid grid-cols-2">
                  <div class="col-span-1 text-theme-gray-4">Reference Number:</div>
                  <div class="col-span-1 font-bold">{{ data.refNumber }}</div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-span-1 flex flex-col gap-4 pt-4">
         <div class="grid grid-cols-2 gap-4">
            <div class="flex flex-col gap-4 col-span-2 md:col-span-1">
               <div class="grid grid-cols-2">
                  <div class="col-span-1 text-theme-gray-4">Name:</div>
                  <div class="col-span-1 font-bold">{{ data.contact.name }}</div>
               </div>
               <div class="grid grid-cols-2">
                  <div class="col-span-1 text-theme-gray-4">Email:</div>
                  <div class="col-span-1 font-bold">{{ data.contact.email }}</div>
               </div>
            </div>
            <div class="flex flex-col gap-4 col-span-2 md:col-span-1">
               <div class="grid grid-cols-2">
                  <div class="col-span-1 text-theme-gray-4">Phone number:</div>
                  <div class="col-span-1 font-bold">{{ data.contact.phone }}</div>
               </div>
               <div class="grid grid-cols-2">
                  <div class="col-span-1 text-theme-gray-4">Comments:</div>
                  <div class="col-span-1 font-bold">{{ data.comments }}</div>
               </div>
            </div>
         </div>
      </div>
      <div v-if="data.car" class="col-span-1 flex flex-col gap-4 pt-4">
         <div class="grid grid-cols-2 gap-4">
            <div class="flex flex-col gap-4 col-span-2 md:col-span-1">
               <div class="grid grid-cols-2">
                  <div class="col-span-1 text-theme-gray-4">Car:</div>
                  <div class="col-span-1 font-bold">{{ data.car.name }}</div>
               </div>
               <div class="grid grid-cols-2">
                  <div class="col-span-1 text-theme-gray-4">Type:</div>
                  <div class="col-span-1 font-bold">{{ data.car.type.name }}</div>
               </div>
               <div class="grid grid-cols-2">
                  <div class="col-span-1 text-theme-gray-4">Bags:</div>
                  <div class="col-span-1 font-bold">{{ data.car.type.bags }}</div>
               </div>
               <div class="grid grid-cols-2">
                  <div class="col-span-1 text-theme-gray-4">Passengers:</div>
                  <div class="col-span-1 font-bold">{{ data.car.type.passengers }}</div>
               </div>
            </div>
            <div class="flex flex-col gap-4 col-span-2 md:col-span-1">
               <div class="grid grid-cols-2">
                  <div class="col-span-1 text-theme-gray-4">Doors:</div>
                  <div class="col-span-1 font-bold">{{ data.car.type.doors }}</div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-span-1 flex flex-col gap-4 pt-4">
         <div class="grid grid-cols-2 gap-4">
            <div class="flex flex-col gap-4 col-span-2 md:col-span-1">
               <div class="grid grid-cols-2">
                  <div class="col-span-1 text-theme-gray-4">Pick Up:</div>
                  <div class="col-span-1 font-bold">{{ data.search ? data.search.pickUp.location.name : '' }}</div>
               </div>
               <div class="grid grid-cols-2">
                  <div class="col-span-1 text-theme-gray-4">Date and time:</div>
                  <div class="col-span-1 font-bold">{{ data.search ? data.search.pickUp.date : '' }} - {{ data.search ? data.search.pickUp.time : '' }}</div>
               </div>
            </div>
            <div v-if="data.search && data.search.type == 'hourly'" class="flex flex-col gap-4 col-span-2 md:col-span-1">
               <div class="grid grid-cols-2">
                  <div class="col-span-1 text-theme-gray-4">Booking hours:</div>
                  <div class="col-span-1 font-bold">{{ data.search ? data.search.hours : '' }}</div>
               </div>
            </div>
            <div v-else-if="data.search && data.search.type == 'distance'" class="flex flex-col gap-4 col-span-2 md:col-span-1">
               <div class="grid grid-cols-2">
                  <div class="col-span-1 text-theme-gray-4">Drop Off:</div>
                  <div class="col-span-1 font-bold">{{ data.search ? data.search.dropOff.location.name : '' }}</div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-span-1 flex flex-col gap-4 pt-4">
         <div class="grid grid-cols-2 gap-4">
            <div class="flex flex-col gap-4 col-span-2 md:col-span-1">
               <div class="grid grid-cols-2">
                  <div class="col-span-1 text-theme-gray-4">Payment method:</div>
                  <div v-if="data.payment.wallet" class="col-span-1 font-bold">{{ data.payment.wallet }}</div>
               </div>
               <div class="grid grid-cols-2">
                  <div class="col-span-1 text-theme-gray-4">Payment status:</div>
                  <div class="col-span-1 font-bold">{{ data.payment.status }}</div>
               </div>
            </div>
            <div class="flex flex-col gap-4 col-span-2 md:col-span-1">
               <div class="grid grid-cols-2">
                  <div class="col-span-1 text-theme-gray-4">Total amount:</div>
                  <div class="col-span-1 font-bold">{{data.totalAmount}}USD</div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-span-1 flex flex-col gap-4 pt-4">
         <div class="grid grid-cols-2">
            <div class="flex flex-col gap-4 col-span-2">
               <h2 class="font-bold">Cancellation and ammendement policy</h2>
               <ul class="list-disc pl-4">
                  <li>No show charges will be 100%</li>
                  <li>If you cancel your booking before {{ cancellationDealine }} your cancellation charges will be 0. However after that 
                     date the cancellation charges will apply as per our 
                     <span @click="router.push({name: 'TermsConditions'})">Terms and Conditions</span>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue'
import router from '@/router'
import SiteSettings from '@/composables/SiteSettings'
import { getCancellationDeadline } from '@/utils/common'

const props = defineProps(['data'])
const { loadSiteSettings } = SiteSettings()
const cancellationHours = ref<number>(0)
const cancellationDealine = ref<string>('')

const fetchSiteSettings = async () => {

   const response = await loadSiteSettings()

   if (response.status == 200) {
      cancellationHours.value = response.data.cancellation_hours

      if (props.data && props.data.search && props.data.search.pickUp && props.data.search.pickUp.date && props.data.search.pickUp.time) {
         cancellationDealine.value = getCancellationDeadline(props.data.search.pickUp.date, props.data.search.pickUp.time, cancellationHours.value)
      }
   }
}

onMounted(async () => {

   await fetchSiteSettings()
})

</script>