<template>

   <div v-if="!isLoading" class="my-0 md:w-[50%] mx-auto ">
      <div class="grid grid-cols-1 gap-4 divide-y divide-gray-300 w-full bg-gray-100 p-4 my-12">
         <div class="col-span-1 flex flex-row items-center">
            <img class="w-[235px]" src="/assets/logo.png" alt="">
            <div class="ml-auto text-size-6">Booking Voucher</div>
         </div>
         <div v-if="data.refNumber" class="text-size-6">
            <div class="grid grid-cols-2 gap-4 pt-4">
               <div class="flex flex-col gap-4 col-span-2 md:col-span-1">
                  <div class="grid grid-cols-2">
                     <div class="col-span-1 text-theme-gray-4">Reference Number:</div>
                     <div class="col-span-1 font-bold">{{ data.refNumber }}</div>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-span-1 flex flex-col gap-4 pt-4">
            <div class="grid grid-cols-2 gap-4">
               <div class="flex flex-col gap-4 col-span-2 md:col-span-1">
                  <div class="grid grid-cols-2">
                     <div class="col-span-1 text-theme-gray-4">Name:</div>
                     <div class="col-span-1 font-bold">{{ data?.contact.name }}</div>
                  </div>
                  <div class="grid grid-cols-2">
                     <div class="col-span-1 text-theme-gray-4">Email:</div>
                     <div class="col-span-1 font-bold">{{ data?.contact.email }}</div>
                  </div>
               </div>
               <div class="flex flex-col gap-4 col-span-2 md:col-span-1">
                  <div class="grid grid-cols-2">
                     <div class="col-span-1 text-theme-gray-4">Phone number:</div>
                     <div class="col-span-1 font-bold">{{ data?.contact.phone }}</div>
                  </div>
                  <div class="grid grid-cols-2">
                     <div class="col-span-1 text-theme-gray-4">Comments:</div>
                     <div class="col-span-1 font-bold">{{ data?.comments }}</div>
                  </div>
               </div>
            </div>
         </div>
         <div v-if="data.car" class="col-span-1 flex flex-col gap-4 pt-4">
            <div class="grid grid-cols-2 gap-4">
               <div class="flex flex-col gap-4 col-span-2 md:col-span-1">
                  <div class="grid grid-cols-2">
                     <div class="col-span-1 text-theme-gray-4">Car:</div>
                     <div class="col-span-1 font-bold">{{ data?.car.name }}</div>
                  </div>
                  <div class="grid grid-cols-2">
                     <div class="col-span-1 text-theme-gray-4">Type:</div>
                     <div class="col-span-1 font-bold">{{ data?.car.type.name }}</div>
                  </div>
                  <div class="grid grid-cols-2">
                     <div class="col-span-1 text-theme-gray-4">Bags:</div>
                     <div class="col-span-1 font-bold">{{ data?.car.type.bags }}</div>
                  </div>
                  <div class="grid grid-cols-2">
                     <div class="col-span-1 text-theme-gray-4">Passengers:</div>
                     <div class="col-span-1 font-bold">{{ data?.car.type.passengers }}</div>
                  </div>
               </div>
               <div class="flex flex-col gap-4 col-span-2 md:col-span-1">
                  <div class="grid grid-cols-2">
                     <div class="col-span-1 text-theme-gray-4">Doors:</div>
                     <div class="col-span-1 font-bold">{{ data?.car.type.doors }}</div>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-span-1 flex flex-col gap-4 pt-4">
            <div class="grid grid-cols-2 gap-4">
               <div class="flex flex-col gap-4 col-span-2 md:col-span-1">
                  <div class="grid grid-cols-2">
                     <div class="col-span-1 text-theme-gray-4">Pick Up:</div>
                     <div class="col-span-1 font-bold">{{ data?.search ? data.search.pickUp.location.name : '' }}</div>
                  </div>
                  <div class="grid grid-cols-2">
                     <div class="col-span-1 text-theme-gray-4">Date and time:</div>
                     <div class="col-span-1 font-bold">{{ data?.search ? data?.search.pickUp.date : '' }} - {{ data?.search ? data.search.pickUp.time : '' }}</div>
                  </div>
               </div>
               <div v-if="data.search && data?.search.type == 'hourly'" class="flex flex-col gap-4 col-span-2 md:col-span-1">
                  <div class="grid grid-cols-2">
                     <div class="col-span-1 text-theme-gray-4">Booking hours:</div>
                     <div class="col-span-1 font-bold">{{ data.search ? data.search.hours : '' }}</div>
                  </div>
               </div>
               <div v-else-if="data.search && data?.search.type == 'distance'" class="flex flex-col gap-4 col-span-2 md:col-span-1">
                  <div class="grid grid-cols-2">
                     <div class="col-span-1 text-theme-gray-4">Drop Off:</div>
                     <div class="col-span-1 font-bold">{{ data.search ? data.search.dropOff.location.name : '' }}</div>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-span-1 flex flex-col gap-4 pt-4">
            <div class="grid grid-cols-2 gap-4">
               <div class="flex flex-col gap-4 col-span-2 md:col-span-1">
                  <div class="grid grid-cols-2">
                     <div class="col-span-1 text-theme-gray-4">Total amount:</div>
                     <div class="col-span-1 font-bold">{{data?.totalAmount}}USD</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import Voucher from '@/components/booking/Voucher.vue'
import { Booking } from '@/types'
import BookingService from '@/services/booking-service'
import { getSessionPrefix, isMakePaymentVisible, saveBookingNumber } from '@/utils/common'
import router from '@/router'
// @ts-ignore
import html2pdf from 'html2pdf.js'
import Modal from '@/widgets/Modal.vue'
import { saveUserSearch } from '@/services/search-service'
import CarService from '@/services/car-service'

const props = defineProps(['number'])
const data = ref<any>(null)
const isLoading = ref<boolean>(true)
const { readBooking } = BookingService()
const pdfContent = ref(null)
const openConfirmModal = ref<boolean>(false)
const showMakePaymentButton = ref<boolean>(false)
const { saveSelectedCar } = CarService()

onMounted(async () => {

   const response = await readBooking(props.number)
   isLoading.value = false
   
   if (response.success) {
      data.value = response.data.data
   } else {
      console.log(response.error)
   }

   showMakePaymentButton.value = response.data.data.search ? isMakePaymentVisible(response.data.data.search.pickUp.date) : false
})

const downloadPDF = () => {

   const content = pdfContent.value
   html2pdf(content).from(content).save()
}

const handleConfirmClose = () => openConfirmModal.value = false

</script>